import { css } from "@emotion/css";
import React, { useEffect } from 'react';
import { markerUrl, resultLayerName, resultSourceName } from '../../constants';

const cardStyle = css({
    position: "absolute",
    top: 12,
    left: 12,
    minWidth: 150,
    maxWidth: 300,
    borderRadius: 8,
    background: "#FFF",
    padding: "8px 12px",
    boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.15)",

    "&>div": {
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
        fontSize: 12,

        "&:not(:last-child)": {
            marginBottom: 2
        }
    },

    "& .title": {
        color: "#393939",
        fontSize: 14,
        fontWeight: 600
    }
});

export const EmbedPlace = ({ map, placeData }) => {

    useEffect(() => {
        map.isStyleLoaded() ? addMarker(placeData.result) : map.once("load", () => addMarker(placeData.result));
    });

    const addMarker = (result) => {
        map.loadImage(markerUrl).then(res => map.addImage("custom-waypoint-marker", res.data));

        map.jumpTo({
            center: result.location,
            zoom: 16
        });

        if (map.getSource(resultSourceName)) {
            map.removeLayer(resultLayerName);
            map.removeSource(resultSourceName);
        }

        map.addSource(resultSourceName, {
            "type": "geojson",
            "data": {
                "type": "FeatureCollection",
                "features": [{
                    "type": "Feature",
                    "geometry": {
                        "type": "Point",
                        "coordinates": [result.location.lng, result.location.lat]
                    }
                }]
            }
        });

        map.addLayer({
            "id": resultLayerName,
            "type": "symbol",
            "source": resultSourceName,
            "layout": {
                "icon-image": "custom-waypoint-marker",
                "icon-size": 1,
                "icon-anchor": "bottom",
            }
        });

        // Persist layer to render on style change
        map.persistLayers([resultLayerName]);
    };

    return (
        <div className={cardStyle}>
            {placeData.description.title && <div className='title'>{placeData.description.title}</div>}
            {placeData.description.subtitle && <div>{placeData.description.subtitle}</div>}
            {placeData.description.address && <div>{placeData.description.address}</div>}
        </div>
    );
};