import React, { useEffect } from 'react';
import { initGA, logPageView } from "./analytics";
import './App.css';
import Map from './components/map/map.js';

function App() {
  useEffect(() => {
    initGA(process.env.MEASUREMENT_ID);
    logPageView();
  }, []);

  return (
    <div className="App">
      <Map />
    </div>
  );
}

export default App;