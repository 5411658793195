import { css } from '@emotion/css';
import React, { useCallback, useEffect, useState } from 'react';
import { homeLayerName, homeSourceName } from '../../../../constants';
import { SvgIcon } from '../../../../icons';
import { getProfile, updateProfile } from '../../../../services/account';
import { genericSearch } from '../../../../services/nmaps';
import { renderToast } from '../../../../utils';
import { removeMenuMarkers } from '../../menu';
import { MenuHeader } from '../header';
import { Share } from '../share';
import { Home } from './home';
import { Lists } from './lists';

const savedStyle = css({

    "& .home-wrapper": {
        display: 'flex',
        alignItems: 'center',
        padding: '12px 16px 12px 24px',
        fontSize: 14,
        borderBottom: '1px solid #F4F4F4',

        "p": {
            margin: 0
        },

        "& .description": {
            flex: 1,
            marginLeft: 12
        },

        "& .add-location": {
            width: 256,
            color: "#757677",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
        },

        "&.without-home:hover": {
            backgroundColor: "#F4F4F4",
            cursor: "pointer"
        },

        "& .home-options": {
            height: 40,
            width: 40,
            padding: 8,

            "&:hover": {
                cursor: "pointer",
                backgroundColor: "#F4F4F4",
                borderRadius: 8
            }
        },

        ".home-list-options": {
            display: "none",
            listStyle: "none",

            "&.show": {
                display: "block",
                zIndex: 1,
                position: 'absolute',
                backgroundColor: "white",
                top: 60,
                right: -100,
                borderRadius: 8,
                padding: "8px 0px",
                width: 150,
                boxShadow: "0px 2px 6px 0px rgba(0, 0, 0, 0.15)",

                "li": {
                    padding: "6px 12px",

                    "&:hover": {
                        backgroundColor: "#F4F4F4",
                        cursor: "pointer"
                    }

                }
            }
        }
    }
});

export const Save = ({ backfn, closefn, map }) => {

    const [userProfile, setUserProfile] = useState(null);
    const [homeLocation, setHomeLocation] = useState(null);
    const [showSaved, setShowSaved] = useState(true);
    const [showHome, setShowHome] = useState(false);
    const [isOpen, setIsOpen] = useState(false);

    const getData = useCallback(() => {
        getProfile().then((userProfile) => { setUserProfile(userProfile); setHomeLocation(null) });
    }, []);

    useEffect(getData, [getData]);

    useEffect(() => {
        if (!map.hasImage("home-marker")) {
            map.loadImage("/img/home.png").then(res => {
                map.addImage("home-marker", res.data)
            }).catch(e => console.log("error", e));
        }
    }, []);

    const handleClickOutside = () => {
        const dropdown = document.querySelector('.home-list-options');
        if (dropdown && dropdown.classList.contains('show')) {
            dropdown.classList.remove('show');
        }
    };

    const home = userProfile?.attributes?.formatted && userProfile?.attributes?.formatted[0];

    const addHome = () => {
        setShowSaved(false);
        setShowHome(true);
    }

    const backHomeFn = () => {
        setShowSaved(true);
        setShowHome(false);
        getData();
    }

    const toggleOptions = (show) => {
        const elem = window.document.getElementsByClassName("home-list-options");
        if (elem.length) show ? elem[0].classList.add("show") : elem[0].classList.remove("show");
    }

    const removeAddress = () => {
        const body = {
            ...userProfile,
            attributes: {
                ...userProfile.attributes,
                "formatted": null
            }
        };

        let toastText;

        updateProfile(body)
            .then(() => toastText = 'Home address removed.')
            .catch(() => toastText = 'Error removing home address. Please try again.')
            .finally(() => {
                toggleOptions(false);
                renderToast(toastText);
                getData();
                removeHomeMarker();
            });
    }

    const removeHomeMarker = () => {
        if (map.getSource(homeSourceName)) {
            if (map.getLayer(homeLayerName)) map.removeLayer(homeLayerName);
            map.removeSource(homeSourceName);
        }
    }

    const addHomeMarker = (result) => {
        removeHomeMarker();

        // Add source
        map.addSource(homeSourceName, {
            "type": "geojson",
            "data": {
                "type": "FeatureCollection",
                "features": [{
                    "type": "Feature",
                    "geometry": {
                        "type": "Point",
                        "coordinates": [result["location"].lng, result["location"].lat]
                    },
                    "properties": {
                        "name": result["name"]
                    }
                }
                ]
            }
        });

        // Add layer
        map.addLayer({
            "id": homeLayerName,
            "type": "symbol",
            "source": homeSourceName,
            "layout": {
                "icon-image": "home-marker",
                "text-size": 14,
                "icon-size": 1,
                "text-field": ["get", "name"],
                "text-anchor": "left",
                "icon-anchor": "bottom",
                "text-offset": [1.2, -1.5],
            },
            "paint": {
                "text-color": "#000000",
                "text-halo-color": "#FFFFFF",
                "text-halo-width": 1.8
            }
        });

        // Persist layer
        map.persistLayers([homeLayerName]);

        // Center map on marker
        map.flyTo({
            center: result["location"],
            zoom: 16,
            speed: 1.3
        });
    }

    const getHomeAddress = () => {
        toggleOptions(false);

        if (!homeLocation) {
            genericSearch({ search_text: home, ref_location: map.getCenter(), max_results: 1 })
                .then((res) => {
                    if (res.length === 0) {
                        renderToast("Address invalid. Try adding another location.")
                    } else {
                        setHomeLocation(res[0]);
                        addHomeMarker(res[0]);
                    }
                })
                .catch(() => renderToast("Address invalid. Try adding another location."))
        }
    }

    const shareHome = () => {
        if (!homeLocation) {
            genericSearch({ search_text: home, ref_location: map.getCenter(), max_results: 1 })
                .then((res) => {
                    if (res.length === 0) {
                        renderToast("It wasn't possible to generate the link at the moment. Try again later.")
                    } else {
                        setHomeLocation(res[0]);
                        setIsOpen(true);
                    }
                })
                .catch(() => renderToast("It wasn't possible to generate the link at the moment. Try again later."))
        } else {
            setIsOpen(true);
        }
    }

    return (
        <>
            {showHome ?
                <Home backfn={backHomeFn} closefn={closefn} userProfile={userProfile} map={map} removeHomeMarker={removeHomeMarker} addHomeMarker={addHomeMarker} />
                :
                <>
                    {showSaved &&
                        <div className={savedStyle} onClick={(e) => { e.stopPropagation(); handleClickOutside() }}>
                            <MenuHeader title="Saved" backfn={() => { removeMenuMarkers(map); backfn() }} closefn={() => { removeMenuMarkers(map); closefn() }} />
                            <div className={`home-wrapper ${home == null && "without-home"}`} onClick={() => !home && addHome()}>
                                <SvgIcon name="ic_home" size={24} color="#2B8FFF" />
                                <div className='description'>
                                    <p>Home</p>
                                    <p className='add-location'>
                                        {home || "Click to add a location"}
                                    </p>
                                </div>
                                {home &&
                                    <>
                                        <SvgIcon name="ic_dots" size={24} color="#393939" className="home-options" onClick={(e) => { e.stopPropagation(); toggleOptions(true) }} />
                                        <div className='home-list-options'>
                                            <li onClick={getHomeAddress}>Show on map</li>
                                            <li onClick={shareHome}>Share</li>
                                            <li onClick={removeAddress}>Remove address</li>
                                        </div>
                                    </>
                                }
                            </div>
                        </div >
                    }
                    <div onClick={(e) => { e.stopPropagation(); handleClickOutside() }}>
                        <Lists map={map} closefn={closefn} setShowSaved={setShowSaved} />
                    </div>
                    <Share open={isOpen} closefn={() => setIsOpen(false)} shareType="place" shareData={{ "payload_type": "place", "payload": homeLocation }} />
                </>
            }
        </>
    )
}
