import axios from "axios";

const client = axios.create({
    baseURL: process.env.REACT_APP_BASIC_URL,
    headers: { "Content-type": "application/json" },
    paramsSerializer: { indexes: null },
    params: {
        access_token: process.env.REACT_APP_ACCESS_TOKEN
    }
});

export function getCategories() {
    return client.get("/v1/places/categories/en")
        .then(r => r.data);
}

export function genericSearch(body) {
    return client.post("/v1/search/generic_search", body)
        .then(r => r.data.results);
}

export function reverseGeocode(body) {
    return client.post("/v1/search/reverse_geocode", body)
        .then(r => r.data.results);
}

export function placeInfo(body) {
    return client.post("/v1/places/info", body)
        .then(r => r.data.results);
}

export function routingCalc(body) {
    return client.post("/v1/routing/calc", body)
        .then(r => r.data);
}