import { css } from '@emotion/css';
import { Modal, Tabs } from 'antd';
import React, { useEffect, useState } from 'react';
import { SvgIcon } from '../../../icons';
import { createHash } from '../../../services/des';
import { renderToast } from '../../../utils';

const modalStyle = css({
    "& .ant-modal-header": {
        marginBottom: 20,
        color: "#393939"
    },

    "& .loading-wrapper": {
        height: 106,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",

        "& .loading": {
            WebkitAnimation: "rotate 400ms linear infinite",
            MozAnimation: "rotate 400ms linear infinite",
            animation: "rotate 400ms linear infinite"
        }
    },

    "& .link-wrapper": {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: '#F4F4F4',
        borderRadius: 4,
        padding: 10,

        "& .url": {
            width: "calc(100% - 24px)",
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap"
        }
    },

    "& .iframe-map": {
        height: 355,
        marginTop: 12,

        "& iframe": {
            height: "100%",
            width: "100%",
            borderColor: "transparent",
            borderRadius: 4
        }
    }
});

export const Share = ({ open, closefn, shareType, shareData }) => {
    const [shareUrl, setShareUrl] = useState(null);
    const [embedUrl, setEmbedUrl] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [iframeKey, setIframeKey] = useState(Date.now());
    const [activeKey, setActiveKey] = useState("link");

    useEffect(() => {
        if (shareType === "map" && open) {
            // Create url
            const url = window.location.href;
            const hashIndex = url.indexOf("#");
            const baseUrl = hashIndex !== -1 ? url.slice(0, hashIndex) : url;
            const hash = hashIndex !== -1 ? url.slice(hashIndex) : "";
            setShareUrl(`${baseUrl}${hash}`);
            setEmbedUrl(`${baseUrl}embed${hash}`);
        } else if (shareType !== "map" && shareData && open) {
            setIsLoading(true);
            let hash = "";
            if (shareData.payload_type === "place") hash = `#16/${shareData.payload.location.lat}/${shareData.payload.location.lng}`
            window.setTimeout(() => {
                createHash(shareData)
                    .then(res => {
                        setShareUrl(`${window.location.origin}${window.location.pathname}?share=${res.id}`)
                        setEmbedUrl(`${window.location.origin}${window.location.pathname}embed?share=${res.id}${hash}`)
                    })
                    .catch(() => {
                        closefn();
                        renderToast("It wasn't possible to generate the link at the moment. Try again later.");
                    })
                    .finally(() => setIsLoading(false))
            }, 500);
        }
    }, [shareData, open]);

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text);
        renderToast("Link copied")
    };

    const handleTabChange = (key) => {
        setActiveKey(key);
        if (key === "embed") setIframeKey(Date.now());
    };

    const linkHTML =
        <div className="link-wrapper">
            <div className="url">{shareUrl}</div>
            <div className="nmapsgl-ctrl-directions-copy-icon nmapsgl-pointer" onClick={() => { copyToClipboard(shareUrl); }}> </div>
        </div>

    const embedHTML =
        <>
            <div className="link-wrapper">
                <div className="url">{`<iframe src="${embedUrl}"></iframe>`}</div>
                <div className="nmapsgl-ctrl-directions-copy-icon nmapsgl-pointer" onClick={() => { copyToClipboard(`<iframe src="${embedUrl}"></iframe>`); }}> </div>
            </div>
            <div className='iframe-map'>
                <iframe key={iframeKey} title="map" src={embedUrl}></iframe>
            </div>
        </>

    const items = [
        {
            key: 'link',
            label: 'Send a link',
            children: linkHTML,
        },
        {
            key: 'embed',
            label: 'Embed map',
            children: embedHTML
        }
    ];

    return (
        <Modal open={open} maskClosable={false} onCancel={() => { setActiveKey("link"); closefn() }} width={536} className={modalStyle} title="Share" footer={null} centered>
            {
                isLoading ?
                    <div className='loading-wrapper'>
                        <SvgIcon name="ic_loading" size={24} className="loading" />
                    </div>
                    : <Tabs activeKey={activeKey} items={items} tabBarStyle={{ width: 180 }} onChange={handleTabChange} />
            }
        </Modal>
    )
}