import { css } from '@emotion/css';
import { Modal, Table } from 'antd';
import React, { useRef, useState } from 'react';
import Draggable from 'react-draggable';

const style = css({
    "& .ant-modal-header": {
        marginBottom: 13,
        color: "#393939"
    },

    "& .description": {
        fontSize: 14,
        fontWeight: 400,
        marginTop: 14,
        WebkitUserSelect: 'none',
        msUserSelect: 'none',
        userSelect: 'none',

        "& a": {
            textDecoration: "underline"
        }
    },

    "& .about-table": {
        marginTop: 8,

        "& .ant-table-thead >tr>th": {
            background: "white",

            "&::before": {
                backgroundColor: "white !important"
            }
        },

        "& .ant-table-thead>tr>th": {
            padding: "12px 16px"
        },

        "& .ant-table-tbody>tr>td": {
            padding: "12px 16px"
        },

        "& .ant-table-tbody tr:last-child td": {
            borderBottom: "none"
        }
    }
});

export const About = ({ open, closefn }) => {
    const [disabled, setDisabled] = useState(true);
    const [bounds, setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 });
    const draggleRef = useRef(null);

    const columns = [
        {
            title: 'Service',
            dataIndex: 'service',
            key: 'service',
            align: 'left'
        },
        {
            title: 'Version',
            dataIndex: 'version',
            key: 'version',
            align: 'right'
        },
    ];

    const data = [
        { "key": "site", "service": "Site", "version": process.env.REACT_APP_SITE_VERSION },
        { "key": "nmapsgl", "service": "Nmaps GL", "version": process.env.REACT_APP_FRAMEWORK_VERSION },
        { "key": "coreapi", "service": "Core API", "version": process.env.REACT_APP_CORE_API_VERSION },
        { "key": "traffic", "service": "Traffic API", "version": process.env.REACT_APP_TRAFFIC_API_VERSION },
        { "key": "maps", "service": "OSM Maps", "version": process.env.REACT_APP_MAPS_VERSION }
    ];

    const onStart = (_event, uiData) => {
        const { clientWidth, clientHeight } = window.document.documentElement;
        const targetRect = draggleRef.current?.getBoundingClientRect();
        if (!targetRect) {
            return;
        }
        setBounds({
            left: -targetRect.left + uiData.x,
            right: clientWidth - (targetRect.right - uiData.x),
            top: -targetRect.top + uiData.y,
            bottom: clientHeight - (targetRect.bottom - uiData.y),
        });
    };

    return (
        <Modal open={open} footer={null} mask={false} maskClosable={false} onCancel={closefn} width={308} className={style}
            title={
                <div
                    style={{ width: '100%', cursor: 'move' }}
                    onMouseOver={() => {
                        if (disabled) {
                            setDisabled(false);
                        }
                    }}
                    onMouseOut={() => {
                        setDisabled(true);
                    }}
                    onFocus={() => { }}
                    onBlur={() => { }}
                >
                    About
                    <div className='description'>You can find more information about this project over <a target='_blank' href='https://ndrive.com/product/nmaps/' rel="noreferrer">here</a>.</div>
                </div>
            }
            modalRender={(modal) => (
                <Draggable
                    disabled={disabled}
                    bounds={bounds}
                    nodeRef={draggleRef}
                    onStart={(event, uiData) => onStart(event, uiData)}
                >
                    <div ref={draggleRef}>{modal}</div>
                </Draggable>
            )}>
            <Table columns={columns} dataSource={data} className='about-table' pagination={false} />
        </Modal>
    )
}