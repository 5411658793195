import * as nmapsgl from '@ndrive/nmaps-gl';
import '@ndrive/nmaps-gl/dist/nmaps-gl.css';
import React, { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { useReactToPrint } from 'react-to-print';
import { DetailActions } from '../directionsDetail/detailActions';
import { RoutingActions } from '../directionsDetail/routingActions';
import { Embed } from '../embed/embed';
import { PrintContent } from '../menu/components/print';
import Menu from '../menu/menu';
import './map.css';

export default function Map() {
  const mapContainer = useRef(null);
  const map = useRef(null);
  const [mapInitialized, setMapInitialized] = useState(false);
  const [embedMap, setEmbedMap] = useState(false);
  const printRef = useRef();
  const hamburguerContainerRef = useRef(null);
  const actionsContainerRef = useRef(null);
  const directionActionsRef = useRef(null);

  useEffect(() => {
    if (map.current) return; // stops map from intializing more than once

    nmapsgl.setApiUrl(process.env.REACT_APP_BASIC_URL)
    nmapsgl.setAccessToken(process.env.REACT_APP_ACCESS_TOKEN);

    const urlContainsEmbed = window.location.pathname.includes("/embed");

    if (urlContainsEmbed) {
      let [zoom, lat, lng] = [4, 5.93, 47.79];
      const hash = window.location.hash;
      if (hash?.startsWith("#")) {
        const parts = hash.substring(1).split("/");
        if (parts.length === 3) {
          [zoom, lat, lng] = parts.map(Number);
        }
      }

      // Add class to tag as embed map
      mapContainer.current.classList.add("embed");

      map.current = new nmapsgl.Map({
        container: mapContainer.current,
        center: [lat, lng],
        zoom: zoom,
        hash: true,
        attributionControl: false,
        preserveDrawingBuffer: true,
        layerOptions: {
          "nmapsgl_pois": {
            initialEnabled: true,
          }
        }
      });

      map.current.addControl(new nmapsgl.NavigationControl({
        showCompass: false
      }), 'bottom-right');

      map.current.addControl(new nmapsgl.StyleControl({ compact: true }), "bottom-left");

      setEmbedMap(true);
    } else {
      map.current = new nmapsgl.Map({
        container: mapContainer.current,
        center: [5.93, 47.79],
        zoom: 4,
        hash: true,
        attributionControl: false,
        canvasContextAttributes: {
          preserveDrawingBuffer: true
        },
        layerOptions: {
          "nmapsgl_street_view": {
            mapillaryAccessToken: "MLY|3851069871664582|8e9c9f076cda1069d371524812123f7b"
          },
          "nmapsgl_opensky": {
            openSkyAuth: btoa("ndrive_devops:voxtun-nYjmi1-byfbaj")
          },
          "nmapsgl_pois": {
            initialEnabled: true,
          }
        }
      });

      map.current.addControl(new nmapsgl.AttributionControl({ compact: false }), "bottom-right");
      map.current.addControl(new nmapsgl.ScaleControl(), "bottom-left");

      map.current.addControl(new nmapsgl.StyleControl({ compact: false }), "bottom-right");

      map.current.addControl(new nmapsgl.RotateControl(), "bottom-right");

      map.current.addControl(new nmapsgl.GeolocateControl({
        positionOptions: {
          enableHighAccuracy: true,
        },
        trackUserLocation: true,
      }), 'bottom-right');

      map.current.addControl(new nmapsgl.NavigationControl({
        showCompass: false
      }), 'bottom-right');

      map.current.addControl(new nmapsgl.NavigationControl({
        showZoom: false
      }), 'bottom-right');

      map.current.addControl(new nmapsgl.LayersControl(), 'top-right');

      hamburguerContainerRef.current = document.createElement('div');
      actionsContainerRef.current = document.createElement('div');
      directionActionsRef.current = document.createElement('div');
      map.current.addControl(new nmapsgl.DirectionControl({
        prependElement: hamburguerContainerRef.current,
        actionsElement: actionsContainerRef.current,
        directionActionsElement: directionActionsRef.current,
        includeActions: false,
        profileIds: ["Car", "Walking"]
      }), 'top-left');
      setMapInitialized(true);
    }
  }, []);

  const handlePrint = useReactToPrint({
    content: () => {
      if (!printRef.current) return;
      const printablemap = printRef.current.querySelector("#printable-map");
      printablemap.style["background-image"] = `url(${map.current.getCanvas().toDataURL("image/png")})`;
      printablemap.classList.remove("with-notes");
      const notes = printRef.current.querySelector("#print-notes");
      notes.style["display"] = "none";
      return printRef.current
    },
  });

  window.addEventListener('keydown', (event) => {
    if ((event.ctrlKey || event.metaKey) && event.key === 'p') {
      event.preventDefault();
      handlePrint()
    }
  });

  return (
    <div className="map-wrap">
      <div id="mapContainer" ref={mapContainer} className="map" />
      <div style={{ display: 'none' }}>
        <PrintContent ref={printRef} />
      </div>
      {mapInitialized &&
        <>
          <Menu map={map.current} printRef={printRef.current} hamburguerContainerRef={hamburguerContainerRef.current} />
          {createPortal(<DetailActions map={map.current} />, actionsContainerRef.current)}
          {createPortal(<RoutingActions map={map.current} />, directionActionsRef.current)}
        </>
      }
      {embedMap && <Embed map={map.current} />}
    </div>
  );
}