import { css } from '@emotion/css';
import React, { useState } from 'react';
import { hasAuthParams, useAuth } from "react-oidc-context";
import { Tooltip } from "react-tooltip";
import { SvgIcon } from '../../../icons';

const style = css({
    borderBottom: "1px solid #F4F4F4",

    "& .profile-wrapper": {
        padding: "16px 12px 16px 16px",
    },

    "& .sign-in-wrapper": {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",

        "& .sign-in-header": {
            display: 'flex',
            alignItems: "center",
        },

        "& .avatar": {
            height: 40,
            width: 40,
            backgroundPosition: "center",
            backgroundSize: 40,
            borderRadius: "50%",
        },

        "& .arrow-right": {
            width: 28,
            height: 28,
            padding: 6,

            "&:hover": {
                cursor: "pointer",
                backgroundColor: "#F4F4F4",
                borderRadius: 8
            },
        },

        "& .profile-description": {
            marginLeft: 12,

            "& .label": {
                color: "#393939",
                fontSize: 16,
            },

            "& .username": {
                color: "#393939",
                fontSize: 15
            },

            "& .email": {
                color: "#757677",
                fontSize: 13
            }
        }
    },

    "& .logout-button": {
        display: 'flex',
        alignItems: 'center',
        padding: "10px 8px",
        borderRadius: 8,
        border: "1px solid #F4F4F4",
        marginTop: 12,
        marginLeft: 52,
        width: 180,
        color: "#393939",
        fontWeight: 600,
        fontSize: 13,

        "&:hover": {
            cursor: "pointer",
            backgroundColor: "#F4F4F4",
        },

        "& .label": {
            marginLeft: 8
        }
    },

    "& .loading": {
        WebkitAnimation: "rotate 400ms linear infinite",
        MozAnimation: "rotate 400ms linear infinite",
        animation: "rotate 400ms linear infinite"
    },

    "& .arrow-tooltip": {
        padding: "4px 6px",
        bordeRadius: 4,
        backgroundColor: "#0B0B0B",
        fontSize: 12
    },

    "& .loading-profile": {
        height: 122,
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    }
});

export const Profile = () => {
    const [logoutInloading, setLogoutInloading] = useState(false);
    const auth = useAuth();

    const logoutFn = () => {
        setLogoutInloading(true);
        window.setTimeout(() =>
            Promise.allSettled([
                auth.removeUser(),
                auth.signoutSilent({ id_token_hint: auth.user.id_token }),
            ])
                .then(() => localStorage.clear())
                .finally(() => setLogoutInloading(false))
            , 300);
    }

    const arrowFn = () => {
        if (auth.isAuthenticated) window.open(process.env.KEYCLOAK_PROFILE_PAGE, "_blank")
        else void auth.signinRedirect({
            redirect_uri: `${window.location.origin}${window.location.pathname}?menu=true${window.location.hash}`,
        })
    }

    const profilePicture = (auth.isAuthenticated && auth.user?.profile.picture) && { backgroundImage: `url(${auth.user?.profile.picture})` };
    const loadingCondition = auth.isLoading && !auth.isAuthenticated && !auth.activeNavigator && hasAuthParams();

    return (
        <div className={style}>
            {loadingCondition ?
                <div className='loading-profile'>
                    <SvgIcon name="ic_loading" size={24} className="loading" />
                </div>
                :
                <div className='profile-wrapper'>
                    <div className='sign-in-wrapper'>
                        <div className="sign-in-header">
                            {
                                profilePicture ?
                                    <div className="avatar" style={profilePicture} /> :
                                    <SvgIcon name="ic_avatar" size={40} />
                            }
                            <div className='profile-description'>
                                {auth.isAuthenticated ?
                                    <>
                                        <div className="username">{auth.user?.profile.name}</div>
                                        <div className="email">{auth.user?.profile.email}</div>
                                    </>
                                    :
                                    <div className="label">Sign in</div>
                                }
                            </div>
                        </div>
                        <SvgIcon name="ic_right" className="arrow-right" size={16} onClick={arrowFn} data-tooltip-id="arrow-tooltip"
                            data-tooltip-content="External option" data-tooltip-place="bottom"
                            data-tooltip-class-name="arrow-tooltip" />
                    </div>
                    {auth.isAuthenticated &&
                        <div className='logout-button' onClick={logoutFn}>
                            {logoutInloading ?
                                <SvgIcon name="ic_loading" size={16} className="loading" /> :
                                <SvgIcon name="ic_logout" size={16} />
                            }
                            <div className='label'>Log out this account</div>
                        </div>
                    }
                    <Tooltip id="arrow-tooltip" />
                </div>
            }
        </div >
    )
}