import { css } from '@emotion/css';
import { Button, Modal } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import React, { forwardRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';

const modalStyle = css({
    "& .ant-modal-header": {
        marginBottom: 20,
        color: "#393939"
    },

    "& .ant-modal-footer": {
        marginTop: 34,

        "& .ant-btn-link": {
            padding: 0
        }
    },
});

const printStyle = css({
    padding: 24,

    "#print-header, #print-footer": {
        display: 'flex',
        justifyContent: 'space-between'
    },

    ".print-body": {
        height: "calc(100vh - 140px)",
        marginTop: 18,

        ".notes": {
            wordBreak: 'break-all',
            height: "70px",
            marginTop: 12
        },

        "#printable-map": {
            width: '100%',
            height: '100%',
            position: 'relative'
        },

        "#printable-map.with-notes": {
            maxHeight: "calc(100% - 80px)"
        }
    },

});

export const PrintContent = forwardRef((props, ref) => {

    const date = new Date().toLocaleDateString('pt-BR', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
    });

    return (
        <div ref={ref} className={printStyle}>
            <div id="print-header">
                <span id="print-title">{window.document.title}</span>
                <span id="print-date">{date}</span>
            </div>
            <div className="print-body">
                <div id="printable-map"></div>
                <div id="print-notes" className='notes' style={{ display: "none" }}></div>
            </div>
            <div id="print-footer">
                <p>{window.location.href}</p>
                <p>Page 1/1</p>
            </div>
        </div>
    );
});

export const Print = ({ open, closefn, map, printRef }) => {
    const [text, setText] = useState("");

    const onChange = (e) => setText(e.target.value);

    const handlePrint = useReactToPrint({
        content: () => {
            if (!printRef) return;
            const printablemap = printRef.querySelector("#printable-map");
            printablemap.style["background-image"] = `url(${map.getCanvas().toDataURL("image/png")})`;
            const notes = printRef.querySelector("#print-notes");
            notes.style["display"] = "none";
            printablemap.classList.remove("with-notes");
            if (text) {
                printablemap.classList.add("with-notes");
                notes.style["display"] = "block";
                notes.textContent = `Notes: ${text}`;
            }
            return printRef;
        },
    });

    return (
        <Modal open={open} maskClosable={false} onCancel={() => { setText(""); closefn() }} width={398} className={modalStyle} title="Add notes" centered
            footer={[
                <Button key="print" type="link" onClick={() => { handlePrint(); setText(""); closefn(); }}>
                    Print
                </Button>
            ]}
        >
            <TextArea showCount value={text} onChange={onChange} maxLength={300} style={{ height: 142, resize: 'none' }} />
        </Modal>
    )
}