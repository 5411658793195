import Icon from '@ant-design/icons';
import React from 'react';

const svgDir = require.context('./', true, /\.svg$/);

export const SvgIcon = ({ name, size, ...svgProps }) => {
    const path = `./${name}.svg`;
    const Component = svgDir(path).default;
    return <Icon component={() => <Component {...svgProps} fontSize={size} />} />;
};
