// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
}

.nmapsgl-ctrl-directions-search-input {
  width: 207px;
}

.menu-wrapper {
  width: 380px;
  border-radius: 8px;
  background-color: #FFFFFF;
  margin-top: 0px !important;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
}

.menu-wrapper.hide {
  display: none;
}

#waypoints-directions-results .message-wrapper {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

/* Mobile rules */

@media (max-width: 450px) {
  .maplibregl-ctrl.maplibregl-ctrl-group:has(.nmapsgl-ctrl-layers) {
    position: absolute;
    top: 60px;
    right: 0px;
  }

  .maplibregl-ctrl.nmapsgl-ctrl-directions-control {
    max-width: calc(100% - 20px);
    width: 100%;

    .nmapsgl-ctrl-directions-search-input {
      width: calc(100% - 155px)
    }
  }

  .maplibregl-ctrl-top-left {
    width: 100%;
    z-index: 4;
  }

  .menu-wrapper {
    max-width: calc(100% - 20px);
    width: 100%;
  }
}

@media (hover: none) {
  .maplibregl-ctrl.maplibregl-ctrl-group:has(.maplibregl-ctrl-zoom-in, .maplibregl-ctrl-zoom-out) {
    display: none;
  }

  .nmapsgl-popover-container #nmapsgl-ctrl-layers-popover.nmapsgl-popover #nmapsgl_street_view.layer-block {
    display: none;
  }
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;AACX;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,kBAAkB;EAClB,yBAAyB;EACzB,0BAA0B;EAC1B,+CAA+C;AACjD;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,8BAA8B;EAC9B,+BAA+B;AACjC;;AAEA,iBAAiB;;AAEjB;EACE;IACE,kBAAkB;IAClB,SAAS;IACT,UAAU;EACZ;;EAEA;IACE,4BAA4B;IAC5B,WAAW;;IAEX;MACE;IACF;EACF;;EAEA;IACE,WAAW;IACX,UAAU;EACZ;;EAEA;IACE,4BAA4B;IAC5B,WAAW;EACb;AACF;;AAEA;EACE;IACE,aAAa;EACf;;EAEA;IACE,aAAa;EACf;AACF","sourcesContent":["body {\n  margin: 0;\n}\n\n.nmapsgl-ctrl-directions-search-input {\n  width: 207px;\n}\n\n.menu-wrapper {\n  width: 380px;\n  border-radius: 8px;\n  background-color: #FFFFFF;\n  margin-top: 0px !important;\n  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);\n}\n\n.menu-wrapper.hide {\n  display: none;\n}\n\n#waypoints-directions-results .message-wrapper {\n  border-bottom-left-radius: 8px;\n  border-bottom-right-radius: 8px;\n}\n\n/* Mobile rules */\n\n@media (max-width: 450px) {\n  .maplibregl-ctrl.maplibregl-ctrl-group:has(.nmapsgl-ctrl-layers) {\n    position: absolute;\n    top: 60px;\n    right: 0px;\n  }\n\n  .maplibregl-ctrl.nmapsgl-ctrl-directions-control {\n    max-width: calc(100% - 20px);\n    width: 100%;\n\n    .nmapsgl-ctrl-directions-search-input {\n      width: calc(100% - 155px)\n    }\n  }\n\n  .maplibregl-ctrl-top-left {\n    width: 100%;\n    z-index: 4;\n  }\n\n  .menu-wrapper {\n    max-width: calc(100% - 20px);\n    width: 100%;\n  }\n}\n\n@media (hover: none) {\n  .maplibregl-ctrl.maplibregl-ctrl-group:has(.maplibregl-ctrl-zoom-in, .maplibregl-ctrl-zoom-out) {\n    display: none;\n  }\n\n  .nmapsgl-popover-container #nmapsgl-ctrl-layers-popover.nmapsgl-popover #nmapsgl_street_view.layer-block {\n    display: none;\n  }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
