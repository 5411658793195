import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { getHash } from '../../services/des';
import { genericSearch, getCategories, placeInfo, reverseGeocode, routingCalc } from '../../services/nmaps';
import { renderToast } from '../../utils';
import { EmbedPlace } from './embed_place';
import { EmbedRouting } from './embed_routing';

export const Embed = ({ map }) => {

    const [type, setType] = useState("");
    const [routingData, setRoutingData] = useState(null);
    const [placeData, setPlaceData] = useState(null);

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        if (params.get('share')) {
            const hash = params.get("share");
            if (hash.startsWith("P_")) {
                setType("place");
                getHash(hash)
                    .then((data) => {
                        let promise;
                        if (data.type === "poi") {
                            promise = Promise.allSettled([
                                placeInfo({ location: data.location, name: data.name, category_id: data.poi_info?.categories[0] }),
                                getCategories()
                            ]).then((results) => {
                                const poi = results[0].value[0];
                                const categories = results[1].value.categories;
                                // Get categories
                                let label;
                                categories.forEach((c) => {
                                    if (c.id === poi.poi_info.categories[0]) label = c.name;
                                    c.children?.forEach((ch) => {
                                        if (ch.id === poi.poi_info.categories[0]) label = ch.name;
                                    });
                                });
                                return { result: poi, description: { "title": poi.name, "subtitle": label, "address": poi.formatted_address } }
                            });
                        } else if (data.type === "house_number") {
                            promise = genericSearch({ search_text: data.formatted_address, ref_location: data.location, max_results: 1 })
                                .then((res) => {
                                    const result = res[0];
                                    const address = result.areas.sort((a, b) => a.level - b.level).slice(0, 2).map(area => area.name).reverse().join(", ");
                                    return ({ result: result, description: { "title": result.name, "address": address } });
                                })
                        } else {
                            promise = reverseGeocode({ location: data.location, result_types: [data.type], allow_unnamed: false })
                                .then((res) => {
                                    const result = res[0];
                                    let address = "";
                                    if (result.type === "state") address = `${result.areas.find(a => a.level === 1).name} `
                                    else if (result.type === "postal_code" || result.type === "street" || result.type === "area") {
                                        address = result.areas.sort((a, b) => a.level - b.level).slice(0, 2).map(area => area.name).reverse().join(", ");
                                    }
                                    return ({ result: result, description: { "title": result.name, "address": address } });
                                })
                        }
                        promise.then((placeData) => setPlaceData(placeData));
                    })
                    .catch((e) => {
                        renderToast("It wasn't possible to access the link content.");
                    })
            } else if (hash.startsWith("R_")) {
                setType("routing");
                getHash(hash)
                    .then((data) => {
                        routingCalc({ ...data, "alternatives": 2, "directions": true, "geometry": true })
                            .then((result) => setRoutingData({ ...data, ...result }));
                    });
            }
        }
    }, []);


    return (
        <>
            {(type === "place" && !isEmpty(placeData)) && <EmbedPlace map={map} placeData={placeData} />}
            {(type === "routing" && !isEmpty(routingData)) && <EmbedRouting map={map} routingData={routingData} />}
        </>
    );
}