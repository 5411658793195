// Menu.js
import { css } from '@emotion/css';
import { DirectionControl } from '@ndrive/nmaps-gl';
import '@ndrive/nmaps-gl/dist/nmaps-gl.css';
import { Modal } from 'antd';
import { omit } from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { useAuth } from 'react-oidc-context';
import { Tooltip } from 'react-tooltip/dist/react-tooltip';
import { homeLayerName, homeSourceName, placesLayerName, placesSourceName } from '../../constants';
import { SvgIcon } from '../../icons';
import { acceptInvite, getHash, getInvite, getList } from '../../services/des';
import { genericSearch, placeInfo, reverseGeocode, routingCalc } from '../../services/nmaps';
import { renderToast } from '../../utils';
import { About } from './components/about';
import { MenuHeader } from './components/header';
import { Print } from './components/print';
import { Profile } from './components/profile';
import { DetailList } from './components/save/lists';
import { Save } from './components/save/save';
import { Share } from './components/share';

const menuStyle = css({
    ".menu-wrapper-options": {
        margin: 0,
        padding: 0,

        "& li": {
            listStyleType: "none",
            padding: "12px 24px",
            color: "#393939",
            fontSize: 14,
            display: "flex",
            alignItems: "center",

            "&:hover": {
                cursor: "pointer",
                backgroundColor: "#F4F4F4"
            },

            "& .label": {
                marginLeft: 12
            }
        }
    },

    ".menu-wrapper-footer": {
        height: 56,
        backgroundSize: "contain",
        backgroundImage: 'url("/img/footer.png")'
    }
});

const menuWrapperStyle = css({
    borderRight: "1px solid #F4F4F4",
    height: 48,
    display: 'flex',
    alignItems: 'center',
    marginRight: 8,
    padding: "6px 12px 6px 6px",
    cursor: "pointer",

    "& .menu-tooltip": {
        padding: "4px 6px",
        bordeRadius: 4,
        backgroundColor: "#0B0B0B",
        fontSize: 12,
        lineHeight: "14px",
        boxShadow: "0px 0px 2px 0px rgba(0, 0, 0, 0.20)",
        fontFamily: "Arial",

        "&>div": {
            top: "-3px !important",
            borderRadius: 2
        }
    },
});

const joinModalStyle = css({
    "& .ant-modal-content": {
        padding: 24,
        color: "#393939"
    },

    "& .ant-modal-header": {
        marginBottom: 12
    },

    "& .ant-modal-footer": {
        marginTop: 12,

        "& span": {
            cursor: 'pointer',
            fontWeight: 500
        },

        "& .confirm": {
            color: "#2B8FFF",
            marginLeft: 24
        }
    },

    "& .list-wrapper": {
        display: "flex",
        alignItems: "center",
        padding: "12px 0px 6px",

        ".list-icon": {
            marginRight: 12,
        },

        ".from": {
            color: "#757677"
        }
    },

    "& .share-description": {
        padding: "6px 0px 12px",
    },

    "& .profile-wrapper": {
        display: "flex",
        alignItems: "center",
        padding: "12px 0px",

        "& .avatar": {
            height: 40,
            width: 40,
            backgroundPosition: "center",
            backgroundSize: 40,
            borderRadius: "50%",
            marginRight: 12
        },
    }

})

export function removeMenuMarkers(map) {
    if (map.getSource(homeSourceName)) {
        if (map.getLayer(homeLayerName)) map.removeLayer(homeLayerName);
        map.removeSource(homeSourceName);
    }

    if (map.getSource(placesSourceName)) {
        if (map.getLayer(placesLayerName)) map.removeLayer(placesLayerName);
        map.removeSource(placesSourceName);
    }
}

const JoinModal = ({ open, setOpenShareModal, share, setShare, showMenuFn }) => {
    const auth = useAuth();

    const joinList = () => {
        if (auth.isAuthenticated) {
            acceptInvite(share.detail.id)
                .then(() => {
                    getList(share.detail.place_list_id)
                        .then((listData) => {
                            renderToast(`Joined "${listData.name}" list from owner ${listData.owner.first_name} ${listData.owner.last_name}`)
                            setShare({ ...share, "list": listData });
                            showMenuFn();
                        })
                })
                .catch(() => {
                    setShare(null);
                    renderToast("It wasn't possible to join list. Try again later.")
                })
                .finally(() => {
                    setOpenShareModal(false);
                })
        } else {
            auth.signinRedirect({
                redirect_uri: `${window.location.origin}${window.location.pathname}?share=${share.detail.hash}${window.location.hash}`,
            })
        }
    }

    const profilePicture = (auth.isAuthenticated && auth.user?.profile.picture) && { backgroundImage: `url(${auth.user?.profile.picture})` };

    const cancelFn = () => {
        setOpenShareModal(false);
        setShare(null);
    }

    return (
        <Modal open={open} className={joinModalStyle} title="Join to edit  this list?" maskClosable={false} centered onCancel={cancelFn} footer={
            <div>
                <span onClick={cancelFn}>Cancel</span>
                <span className='confirm' onClick={joinList}>Join List</span>
            </div>
        }>
            <div className='list-wrapper'>
                <SvgIcon name={share?.detail?.is_protected ? "ic_list_lock" : "ic_list"} size={24} color="#393939" className="list-icon" />
                <div className='description'>
                    <div className='name'>{share?.detail?.place_list_name}</div>
                    <div className='from'>from {share?.detail?.user?.first_name} {share?.detail?.user?.last_name}</div>
                </div>
            </div>
            <div className='share-description'>
                Anyone with access to this list will see your name and profile picture. You can remove yourself from the list at any moment.
            </div>
            {auth.isAuthenticated ?
                <div className='profile-wrapper'>
                    {
                        profilePicture ?
                            <div className="avatar" style={profilePicture} /> :
                            <SvgIcon className="avatar" name="ic_avatar" size={40} />
                    }
                    <div className="username">{auth.user?.profile.name}</div>
                </div>
                :
                <div className='profile-wrapper'>
                    You have to log in to join the list.
                </div>
            }
        </Modal>
    )
}

const Menu = ({ map, printRef, hamburguerContainerRef }) => {
    const [menuInitialized, setMenuInitialized] = useState(false);
    const directionsControlRef = useRef(null);
    const menuContainerRef = useRef(null);
    const [showMenu, setShowMenu] = useState(false);
    const [share, setShare] = useState(null);
    const [openShareModal, setOpenShareModal] = useState(false);

    const showMenuFn = (e) => {
        if (e) e.stopPropagation();

        // Hide wrappers
        directionsControlRef.current._searchWrapper.classList.remove('show');
        directionsControlRef.current._searchWrapper.classList.add('hide');
        directionsControlRef.current._categoriesWrapper.classList.remove('show');
        directionsControlRef.current._historyWrapper.classList.remove('show');
        directionsControlRef.current._resultsWrapper.classList.remove('show');

        // Clear input
        const input = document.getElementById("search-input");
        input.value = "";
        directionsControlRef.current.updateInput(input.value);

        // Show menu
        menuContainerRef.current.classList.remove('hide');
        setShowMenu(true);
    };

    const backMenu = () => {
        directionsControlRef.current._searchWrapper.classList.remove('hide');
        directionsControlRef.current._searchWrapper.classList.add('show');
        menuContainerRef.current.classList.add('hide');
        setShowMenu(false);
    };

    useEffect(() => {
        // Initiate directions control reference
        directionsControlRef.current = map._controls.find(c => c instanceof DirectionControl);

        // Add menu wrapper to control group
        menuContainerRef.current = document.createElement('div');
        menuContainerRef.current.className = 'maplibregl-ctrl menu-wrapper hide';
        const ctrlGroupLeft = window.document.getElementsByClassName("maplibregl-ctrl-top-left")[0];
        ctrlGroupLeft.append(menuContainerRef.current);

        // Set control variable
        setMenuInitialized(true);

        // Show Menu if location contains query params
        const params = new URLSearchParams(window.location.search);
        if (params.get('menu')) showMenuFn();

        // Check if is a share
        if (params.get('share')) {
            const hash = params.get("share");
            switch (true) {
                case hash.startsWith("PLI_"):
                    getInvite(hash)
                        .then((data) => {
                            setShare({ "detail": { ...data, hash: hash } });
                            if (data.is_invite_accepted || data.mode === "view") {
                                getList(data.place_list_id)
                                    .then((listData) => {
                                        setShare({ "detail": data, "list": listData });
                                        showMenuFn();
                                    })
                            } else {
                                setOpenShareModal(true);
                            }
                        })
                        .catch((e) => {
                            renderToast("It wasn't possible to access the link content.");
                        })
                    break;
                case hash.startsWith("P_"):
                    getHash(hash)
                        .then((data) => {
                            let promise;
                            if (data.type === "poi") {
                                promise = placeInfo({ location: data.location, name: data.name, category_id: data.poi_info?.categories[0] })
                            } else if (data.type === "house_number") {
                                promise = genericSearch({ search_text: data.formatted_address, ref_location: data.location, max_results: 1 })
                            }
                            else {
                                promise = reverseGeocode({ location: data.location, result_types: [data.type], allow_unnamed: false })
                            }
                            promise.then(res => {
                                if (res.length !== 0) {
                                    const input = document.getElementById("search-input");
                                    directionsControlRef.current.showResult(input, res[0], true, true);
                                }
                            })
                        })
                        .catch((e) => {
                            renderToast("It wasn't possible to access the link content.");
                        })
                    break;
                case hash.startsWith("R_"):
                    getHash(hash)
                        .then((data) => {
                            const routingCriteria = {
                                ...omit(data, ["id"]),
                                waypoints: data.waypoints.map(wp => {
                                    return {
                                        "name": wp.name, "formatted_address": wp.formatted_address, "location": { "lat": wp.lat, "lng": wp.lng }
                                    }
                                })
                            }
                            directionsControlRef.current.addRoutingData(routingCriteria);
                            routingCalc({ ...data, "alternatives": 2, "directions": true, "geometry": true })
                                .then(res => {
                                    directionsControlRef.current.showRoutingResult(res);
                                })
                                .catch(() => {
                                    const waypointDirectionsWrapper = window.document.getElementById("waypoints-directions-results");
                                    if (waypointDirectionsWrapper)
                                        waypointDirectionsWrapper.innerHTML = `
                                        <div class="message-wrapper">
                                            <div class="message-icon directions-warning-icon"></div>
                                            <div class="message-description">
                                                Unfortunately we couldn’t calculate directions for this type of transportation.
                                            </div>
                                        </div>
                                    `;
                                })
                        })
                        .catch((e) => {
                            renderToast("It wasn't possible to access the link content.");
                        })
                    break;
                default:
                    break;
            }
        }
    }, []);

    return (
        <>
            {menuInitialized && createPortal(<HamburgerButton onClick={showMenuFn} />, hamburguerContainerRef)}
            {menuInitialized && createPortal(<MenuComponent onBack={backMenu} map={map} printRef={printRef} showMenu={showMenu} share={share} />, menuContainerRef.current)}
            <JoinModal open={openShareModal} setOpenShareModal={setOpenShareModal} share={share} setShare={setShare} showMenuFn={showMenuFn} />
        </>
    );
}

const HamburgerButton = ({ onClick }) => (
    <div className={menuWrapperStyle} onClick={onClick}>
        <SvgIcon name="ic_menu" size={24} data-tooltip-id="menu-tooltip"
            data-tooltip-content="Menu" data-tooltip-place="bottom"
            data-tooltip-class-name="menu-tooltip" data-tooltip-delay-show={1000}
        />
        <Tooltip id="menu-tooltip" />
    </div>
);

const MenuComponent = ({ onBack, map, printRef, showMenu, share }) => {
    const auth = useAuth();
    const [openAbout, setOpenAbout] = useState(false);
    const [openPrint, setOpenPrint] = useState(false);
    const [openShare, setOpenShare] = useState(false);
    const [openSaved, setOpenSaved] = useState(false);
    const [shareData, setShareData] = useState(null);

    const handleClickOutside = useCallback((e) => {
        // Avoid close if modal is render
        if (document.querySelector('.ant-modal-wrap')) return;

        const dropdown = document.querySelector('.home-list-options .list-options');
        if (dropdown && dropdown.classList.contains('show')) {
            dropdown.classList.remove('show');
        } else {
            setOpenSaved(false);
            removeMenuMarkers(map);
            onBack();
        }
    }, []);

    useEffect(() => {
        if (showMenu) {
            window.addEventListener('click', handleClickOutside);
        } else {
            window.removeEventListener('click', handleClickOutside);
        }
    }, [showMenu]);

    useEffect(() => {
        setShareData(share)
    }, [share]);

    return (
        (shareData && shareData.list) ?
            <DetailList map={map} list={shareData.list} backfn={() => setShareData(null)} closefn={() => { setShareData(null); onBack() }}
                sharingMode={shareData.list.owner?.id !== auth.user?.profile.sub ? shareData.detail.mode : null} />
            :
            (
                openSaved ? (<Save closefn={() => { setOpenSaved(false); onBack() }} backfn={() => setOpenSaved(false)} map={map} />) : (
                    <div className={menuStyle} onClick={(e) => e.stopPropagation()}>
                        <MenuHeader title="Menu" backfn={onBack} />
                        <ul className="menu-wrapper-options">
                            <Profile />
                            {auth.isAuthenticated &&
                                <li onClick={() => setOpenSaved(true)}>
                                    <SvgIcon name="ic_save" size={24} />
                                    <div className='label'>Saved</div>
                                </li>
                            }
                            <li onClick={() => setOpenShare(true)}>
                                <SvgIcon name="ic_link" size={24} />
                                <div className='label'>Share or embed map</div>
                            </li>
                            <li onClick={() => setOpenPrint(true)}>
                                <SvgIcon name="ic_print" size={24} />
                                <div className='label'>Print</div>
                            </li>
                            <li
                                onClick={() =>
                                    window.open('https://nmaps.zendesk.com/hc/en-us/requests/new?ticket_form_id=7526151664530', '_blank')
                                }
                            >
                                <SvgIcon name="ic_feedback" size={24} />
                                <div className='label'>Feedback</div>
                            </li>
                            <li onClick={() => window.open('https://nmaps.zendesk.com/hc/en-us/sections/4411798208274-FAQ', '_blank')}>
                                <SvgIcon name="ic_help" size={24} />
                                <div className='label'>Help</div>
                            </li>
                            <li onClick={() => setOpenAbout(true)}>
                                <SvgIcon name="ic_about" size={24} />
                                <div className='label'>About</div>
                            </li>
                        </ul>
                        <div className="menu-wrapper-footer"></div>
                        <About open={openAbout} closefn={() => setOpenAbout(false)} />
                        <Print open={openPrint} closefn={() => setOpenPrint(false)} map={map} printRef={printRef} />
                        <Share open={openShare} closefn={() => setOpenShare(false)} shareType="map" />
                    </div>)
            )
    );
}

export default Menu;
