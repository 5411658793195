import toastify from 'toastify-js';
import { toastDefinitions } from './constants';

export function getFormatedAddress(record) {
    const escapedName = record.name.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
    const regex = new RegExp(`${escapedName}, \\s * (.*)`);
    const match = record.formatted_address.match(regex);
    return match ? match[1].trim() : record.formatted_address.trim();
};

export function renderToast(text) {
    // Initialize toast node
    const toastContent = document.createElement('div');
    toastContent.className = 'nmapsgl-toast-content';
    toastContent.innerText = text;

    // Delete previous toast if still rendering
    const previoustToast = window.document.getElementsByClassName("nmapsgl-toast");
    if (previoustToast && previoustToast.length) previoustToast[0].remove();

    toastify({
        ...toastDefinitions,
        node: toastContent,
    }).showToast();
}