import { css } from '@emotion/css';
import { DirectionControl } from '@ndrive/nmaps-gl';
import { Checkbox, Dropdown, Modal } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import React, { useCallback, useEffect, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { SvgIcon } from '../../icons';
import { createList, createPlace, deletePlace, getLists, isPlaceInList } from '../../services/des';
import { renderToast } from '../../utils';
import { Share } from '../menu/components/share';

const modalStyle = css({
    "& .ant-modal-header": {
        marginBottom: 20
    },

    "& .ant-modal-footer": {
        marginTop: 40,

        "& .create-list": {
            color: "#757677",

            "&.active": {
                color: "#2B8FFF",
                cursor: 'pointer'
            }
        }
    }
});

const saveBtnStyle = css({
    "&.pressed": {
        backgroundColor: "#F4F4F4"
    },

    "& .loading": {
        WebkitAnimation: "rotate 400ms linear infinite",
        MozAnimation: "rotate 400ms linear infinite",
        animation: "rotate 400ms linear infinite"
    }
});

const saveDetailStyle = css({
    "& .ant-dropdown-menu": {
        padding: 0,
        width: 150,

        "& .ant-dropdown-menu-item": {
            padding: 0,
            cursor: 'auto',

            "&:hover:not(:last-child)": {
                backgroundColor: 'transparent',
            }
        },

        "& .ant-dropdown-menu-item-divider": {
            margin: "12px 0px 0px"
        },
    },

    "& .title": {
        color: "#393939",
        fontWeight: 600,
        padding: 12
    },

    "& .list-wrapper": {
        display: "flex",
        flexDirection: "column",
        padding: "0 12px",

        "& .list-item:not(:last-child)": {
            marginBottom: 12
        }
    },

    "& .new-list": {
        display: 'flex',
        padding: 12,
        cursor: 'pointer',

        "& .icon": {
            marginRight: 8
        }
    }
});

const actionsStyle = css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 12,
    gap: 8,
    borderBottom: "1px solid #F4F4F4",

    "&> *": {
        flex: 1
    },

    "& .action-btn": {
        padding: "12px 4px 8px",
        fontSize: 14,
        borderRadius: 8,
        border: "1px solid #F4F4F4",
        color: "#393939",
        fontWeight: 600,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        cursor: "pointer",

        "&:hover": {
            backgroundColor: "#F4F4F4"
        },

        "& .icon": {
            marginBottom: 8
        }
    }
})

const SaveDetail = ({ currentResult }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [isSaved, setIsSaved] = useState(false);
    const [lists, setLists] = useState([]);
    const [text, setText] = useState("");
    const [openNewList, setOpenNewList] = useState(false);

    const getData = useCallback(() => {
        if (currentResult) {
            return Promise.allSettled([
                getLists(),
                isPlaceInList(currentResult)
            ])
                .then((results) => {
                    const lists = results[0].value;
                    const savedResults = results[1].value;
                    const merged = lists.map(l => {
                        const relatedPlace = savedResults.find(r => r.place_list_id === l.id);
                        return {
                            ...l,
                            place_id: relatedPlace ? relatedPlace.place_id : null
                        }
                    })
                    setIsSaved(savedResults.length !== 0);
                    setLists(merged);
                })
        }
        return Promise.resolve();
    }, [currentResult]);

    useEffect(() => {
        getData();
    }, [getData, currentResult]);

    const handleVisibleChange = (visible) => setIsOpen(visible);

    const savePlace = (e, l) => {
        const checked = e.target.checked;
        setIsSaving(true);

        if (checked) {
            window.setTimeout(() => {
                createPlace(l.id, currentResult)
                    .then(() => {
                        renderToast(`Added to "${l.name}"`)
                    })
                    .catch(() => {
                        renderToast("It wasn't possible to save your changes at the moment. Try again later.")
                    })
                    .finally(() => {
                        getData().then(() => setIsSaving(false))
                    });
            }, 300);
        } else {
            window.setTimeout(() => {
                deletePlace(l.id, l.place_id)
                    .then(() => {
                        renderToast(`Removed from "${l.name}"`)
                    })
                    .catch(() => {
                        renderToast("It wasn't possible to save your changes at the moment. Try again later.")
                    })
                    .finally(() => {
                        getData().then(() => setIsSaving(false))

                    });
            }, 300);
        }
    }

    const createListFn = () => {
        if (text) {
            createList({ "name": text })
                .then(() => renderToast(`"${text}" list was created.`))
                .catch(() => renderToast("It wasn't possible to save your changes at the moment. Try again later."))
                .finally(() => {
                    setText("");
                    setOpenNewList(false);
                    getData();
                    setIsOpen(true);
                })
        }
    };

    const onChange = (e) => {
        setText(e.target.value);
        const createListBtn = window.document.getElementById("create-list-btn");
        if (createListBtn)
            e.target.value ? createListBtn.classList.add("active") : createListBtn.classList.remove("active");
    };

    const handleEnterKeyDown = (e) => {
        if (e.key === 'Enter') e.preventDefault(); // Evita a nova linha
    };

    const items = (lists) => [
        {
            label: <div className='title'>Save in</div>,
            key: 'title',
        },
        {
            label:
                <div className='list-wrapper'>
                    {lists.length ? lists.map(l => (<Checkbox key={l.id} checked={l.place_id != null} onChange={(e) => savePlace(e, l)} className='list-item'>{l.name}</Checkbox>)) : <div>No lists</div>}
                </div>,
            key: 'lists',
        },
        {
            type: 'divider',
        },
        {
            label:
                <div className="new-list" onClick={() => setOpenNewList(true)}>
                    <SvgIcon className="icon" name="ic_plus" size={16} color="#2B8FFF" />
                    <div> New List </div>
                </div>,
            key: 'new-list',
        },
    ];

    return (
        <>
            <Dropdown open={isOpen} overlayClassName={saveDetailStyle} menu={{ items: items(lists) }} trigger={['click']}
                onOpenChange={handleVisibleChange}>
                <div className={`action-btn ${saveBtnStyle} ${isOpen && "pressed"}`}>
                    {isSaving ? <SvgIcon name="ic_loading" size={24} className="loading" /> :
                        <SvgIcon name={isSaved ? "ic_saved" : "ic_save"} className="icon" size={24} />}
                    <div> Save </div>
                </div>
            </Dropdown>
            <Modal open={openNewList} maskClosable={false} className={modalStyle} onCancel={() => { setText(""); setOpenNewList(false) }} width={446} title="New List"
                centered footer={
                    <div id="create-list-btn" className='create-list' onClick={createListFn}>Create list</div>
                }>
                <TextArea showCount value={text} onChange={onChange} onKeyDown={handleEnterKeyDown} maxLength={50} style={{ height: 36, resize: 'none' }} placeholder='List name' />
            </Modal>
        </>
    );
}

const ShareDetail = ({ currentResult }) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <>
            <div className="action-btn" onClick={() => setIsOpen(true)}>
                <SvgIcon name="ic_share" className="icon" size={24} />
                <div> Share </div>
            </div>
            <Share open={isOpen} closefn={() => setIsOpen(false)} shareType="place" shareData={{ "payload_type": "place", "payload": currentResult }} />
        </>
    )
}

export const DetailActions = ({ map }) => {
    const auth = useAuth();
    const directionControl = map?._controls?.find(c => c instanceof DirectionControl);
    const [currentResult, setCurrentResult] = useState(null);

    useEffect(() => {
        directionControl.eventTarget.addEventListener("openDetail", () => {
            setCurrentResult(directionControl.currentResult);
        })
    }, [])

    return (
        <div className={actionsStyle}>
            <div className="action-btn" onClick={() => directionControl.linkToDirections(currentResult)}>
                <SvgIcon name="ic_directions" className="icon" size={24} />
                <div> Directions </div>
            </div>
            {auth.isAuthenticated && <SaveDetail currentResult={currentResult} />}
            <ShareDetail currentResult={currentResult} />
        </div>
    );
}
