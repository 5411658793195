import { UserOutlined } from '@ant-design/icons';
import { css } from "@emotion/css";
import { LngLatBounds } from "@ndrive/nmaps-gl";
import { Avatar, Button, Form, Modal, Radio, Space, Switch, Tabs } from "antd";
import TextArea from "antd/es/input/TextArea";
import { omit } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useAuth } from "react-oidc-context";
import { placesLayerName, placesSourceName } from "../../../../constants";
import { SvgIcon } from "../../../../icons";
import { createList, createPlace, deleteEditor, deleteList, deletePlace, getList, getLists, inviteList, updateList, updatePlace } from "../../../../services/des";
import { genericSearch } from "../../../../services/nmaps";
import { getFormatedAddress, renderToast } from "../../../../utils";
import { MenuHeader } from "../header";
import { Share } from '../share';

const tabsStyle = css({
    ".ant-tabs-tab": {
        flex: 1,
        justifyContent: "center"
    },

    ".ant-tabs .ant-tabs-tab+.ant-tabs-tab": {
        margin: 0
    },

    ".ant-tabs-nav>div:nth-of-type(1)": {
        display: "unset !important",
        width: "100% !important"
    },

    ".ant-tabs-tab-btn": {
        fontWeight: 500
    },

    "ant-tabs-tab-active": {
        color: "#2B8FFF !important",
    },

    ".ant-tabs-ink-bar": {
        background: "#2B8FFF !important"
    },

    ".ant-tabs-nav": {
        marginBottom: 0,
        display: "none"
    },

    ".loading-wrapper": {
        height: 100,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",

        "& .loading": {
            WebkitAnimation: "rotate 400ms linear infinite",
            MozAnimation: "rotate 400ms linear infinite",
            animation: "rotate 400ms linear infinite"
        }
    }
});

const modalStyle = css({
    "& .ant-modal-header": {
        marginBottom: 20
    },

    "& .ant-modal-footer": {
        marginTop: 40,

        "& .create-list": {
            color: "#757677",

            "&.active": {
                color: "#2B8FFF",
                cursor: 'pointer'
            }
        }
    },
});

const shareModalStyle = css({
    color: "#393939",

    ".loading-wrapper": {
        height: 108,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",

        "& .loading": {
            WebkitAnimation: "rotate 400ms linear infinite",
            MozAnimation: "rotate 400ms linear infinite",
            animation: "rotate 400ms linear infinite"
        }
    },

    ".ant-modal-close": {
        top: 20,
        insetInlineEnd: 16
    },

    "&.hidden-header .ant-modal-header": {
        visibility: "hidden"
    },

    "& .ant-modal-header": {
        marginBottom: 20,
    },

    "& .ant-modal-content": {
        padding: "24px",
    },

    "& .ant-modal-body": {
        "& .ant-radio-group": {
            width: "100%",

            "& .ant-space": {
                rowGap: 20,
                width: "100%",

                "& .ant-space-item": {
                    width: "100%",

                    "& .ant-radio-wrapper": {
                        width: "100%",

                        "& > span:last-child": {
                            width: "100%",
                            paddingInlineEnd: 0
                        },

                        "& .ant-radio-inner": {
                            width: 20,
                            height: 20,

                            "&::after": {
                                width: 20,
                                height: 20,
                                marginBlockStart: -10,
                                marginInlineStart: -10
                            }
                        },

                        "& .ant-radio-checked .ant-radio-inner": {
                            borderColor: "#2B8FFF",
                            backgroundColor: "#2B8FFF",

                            "&::after": {
                                transform: "scale(0.475)"
                            }
                        }
                    }
                }
            },
        },

        "& .link-btn": {
            height: 38,
            borderRadius: 8,
            fontWeight: 500,
            color: "#2B8FFF",
            padding: "10px 8px",
            border: "1px solid #F4F4F4",
            backgroundColor: "#FFFFFF",
            boxShadow: "none",

            "&:disabled": {
                opacity: "0.5"
            }
        },

        "& .share-option-wrapper": {
            marginLeft: 8,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",

            "& .share-option-description": {
                "& .title": {
                    fontWeight: 600
                }
            }
        },

        "& .edit-wrapper": {
            borderTop: "1px solid #F4F4F4",
            margin: "16px -24px 0px",

            "& .edit-wrapper-header": {
                padding: "16px 24px",

                "& .title": {
                    fontWeight: 600,
                    marginBottom: 4
                },

                "& .switch-wrapper": {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",

                    ".ant-switch": {
                        backgroundColor: "#FFFFFF",
                        border: "1px solid #8C8C8D",

                        "&.ant-switch-checked": {
                            backgroundColor: "#2B8FFF",
                            border: "1px solid transparent",

                            "& .ant-switch-handle": {
                                insetInlineStart: "calc(100% - 18px)",
                                "&::before": {
                                    backgroundColor: "#FFFFFF"
                                }
                            }
                        },

                        "& .ant-switch-handle": {
                            width: 14,
                            height: 14,
                            top: 3,
                            insetInlineStart: 4,

                            "&::before": {
                                backgroundColor: "#5E5F60"
                            }
                        }
                    }
                },
            },

            "& .editors-wrapper": {
                borderTop: "1px solid #F4F4F4",

                "& .header": {
                    padding: "16px 24px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    borderBottom: "1px solid #F4F4F4",

                    "& .title": {
                        fontWeight: 600,
                    },
                },

                "& .editors-list": {
                    overflowY: "auto",
                    overflowX: "hidden",
                    maxHeight: "calc(100vh - 370px)",
                    scrollbarWidth: "thin",
                    scrollbarColor: "#E0E0E0 transparent",
                    marginBottom: -12,

                    "& .editors-list-item": {
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        padding: "12px 12px 12px 24px",

                        "& .editor-description-wrapper": {
                            display: "flex",
                            alignItems: "center",

                            "& .editor-label": {
                                marginLeft: 12,

                                ".sub-title": {
                                    color: "#757677"
                                }
                            }
                        },

                        "& .avatar": {
                            height: 40,
                            width: 40,
                            backgroundPosition: "center",
                            backgroundSize: 40,
                            borderRadius: "50%",
                        },

                        "& .remove": {
                            height: 40,
                            width: 40,
                            padding: 12,

                            "&:hover": {
                                cursor: "pointer",
                                backgroundColor: "#F4F4F4",
                                borderRadius: 8
                            }
                        }
                    }
                }
            }
        },

        "& .share-link-wrapper": {
            "& .share-link-header": {
                display: "flex",
                alignItems: "center",
                marginBottom: 12,

                "& .description": {
                    marginLeft: 16,

                    "& .title": {
                        fontWeight: 600
                    },

                    "& .sub-title": {
                        color: "#757677"
                    }
                }
            },

            "& .share-link-box": {
                backgroundColor: "#F4F4F4",
                padding: "8px 12px",
                borderRadius: 4,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",

                "& .share-url": {
                    width: "calc(100% - 24px)",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap"
                }
            }
        }
    }
})

const listsStyle = css({
    overflow: "hidden",
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,

    ".header": {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: "8px 16px 8px 24px",
        borderBottom: "1px solid #F4F4F4",

        ".label": {
            fontSize: 15,
            fontWeight: 400
        },

        ".new-list": {
            color: "#2B8FFE",
            fontWeight: 600,
            padding: 10,

            "&:hover": {
                backgroundColor: "#EAF4FF",
                borderRadius: 8,
                cursor: 'pointer'
            }
        }
    },

    ".list-wrapper": {
        overflowY: "auto",
        overflowX: "hidden",
        maxHeight: "calc(100vh - 260px)",
        scrollbarWidth: "thin",
        scrollbarColor: "#E0E0E0 transparent",

        ".empty-lists": {
            height: 60,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "#757677"
        },

        ".list-item": {
            padding: "12px 12px 12px 24px",

            "&:hover": {
                backgroundColor: "#F4F4F4",
                cursor: 'pointer'
            },

            ".description-wrapper": {
                display: "flex",
                alignItems: "center",

                ".list-icon": {
                    marginRight: 12,
                },

                ".description": {
                    display: "flex",
                    flexDirection: "column",
                    width: "calc(100% - 36px)",

                    ".name": {
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                    },

                    ".places": {
                        fontSize: 14,
                        color: "#757677"
                    }
                }
            }
        }
    }
});

const listItemStyle = css({
    ".list-item-detail": {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "12px 12px 12px 24px",
        position: "relative",

        ".description-wrapper": {
            display: "flex",
            alignItems: "center",
            width: "calc(100% - 40px)",

            ".list-icon": {
                marginRight: 12,
            },

            ".description": {
                display: "flex",
                flexDirection: "column",
                fontSize: 14,
                width: "100%",

                ".name": {
                    display: "-webkit-box",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    WebkitLineClamp: 2,
                    wordBreak: "break-word",
                    "-webkit-box-orient": "vertical"
                },

                ".places": {
                    display: "flex",
                    alignItems: "center",
                    color: "#757677",
                    marginTop: 2,

                    "& .ant-avatar-group": {
                        marginRight: 4,
                        pointerEvents: "none"
                    },

                    "& .owner": {
                        marginRight: 2
                    }
                }
            }
        },

        "& .list-options-btn": {
            height: 40,
            width: 40,
            padding: 8,

            "&:hover": {
                cursor: "pointer",
                backgroundColor: "#F4F4F4",
                borderRadius: 8
            },

            "&.hide": {
                visibility: "hidden"
            }
        },

        ".list-options": {
            display: "none",
            listStyle: "none",

            "&.show": {
                display: "block",
                zIndex: 1,
                position: 'absolute',
                backgroundColor: "white",
                top: 20,
                right: -100,
                borderRadius: 8,
                padding: "8px 0px",
                width: 150,
                boxShadow: "0px 2px 6px 0px rgba(0, 0, 0, 0.15)",

                "li": {
                    padding: "6px 12px",
                    fontSize: 14,

                    "&:hover": {
                        backgroundColor: "#F4F4F4",
                        cursor: "pointer"
                    }

                }
            }
        }
    },

    ".list-description": {
        fontSize: 14,
        padding: "0px 24px 12px",
        wordWrap: "break-word",
        whiteSpace: "pre-wrap"

    }
});

const placeItemStyle = css({
    "&:not(:last-child)": {
        borderBottom: "1px solid #F4F4F4",
        marginBottom: 6
    },

    ".place-item-wrapper": {
        margin: 6,
        padding: 6,
        fontSize: 14,

        "&:hover": {
            backgroundColor: "#F4F4F4",
            borderRadius: 4,
            cursor: 'pointer'
        },

        "&.selected": {
            borderRadius: 4,
            border: "1px solid #2B8FFF",
            boxShadow: "0px 0px 10px 0px rgba(43, 143, 255, 0.10)"
        },

        "&.edit": {
            "&:hover": {
                backgroundColor: 'transparent',
                cursor: 'default'
            },
        },

        ".place-item": {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",

            ".description-wrapper": {
                display: "flex",

                ".description": {
                    marginLeft: 8,
                    maxWidth: "calc(100% - 32px)",

                    ".title": {
                        margin: 0,
                        display: "-webkit-box",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        WebkitLineClamp: 2,
                        wordBreak: "break-word",
                        "-webkit-box-orient": "vertical"
                    },

                    ".subtitle": {
                        margin: 0,
                        color: "#757677",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        WebkitLineClamp: 1
                    }
                }
            },

            "& .place-options-btn": {
                height: 40,
                width: 40,
                padding: 8,

                "&:hover": {
                    cursor: "pointer",
                    backgroundColor: "#E0E0E0",
                    borderRadius: 8
                },

                "&.hide": {
                    visibility: "hidden"
                }
            },

            ".place-options": {
                display: "none",
                listStyle: "none",

                "&.show": {
                    zIndex: 10,
                    top: "100%",
                    display: "block",
                    position: "absolute",
                    backgroundColor: "white",
                    borderRadius: 8,
                    padding: "8px 0px",
                    width: 150,
                    boxShadow: "0px 2px 6px 0px rgba(0, 0, 0, 0.15)",

                    "li": {
                        padding: "6px 12px",
                        fontSize: 14,

                        "&:hover": {
                            backgroundColor: "#F4F4F4",
                            cursor: "pointer"
                        }

                    }
                }
            }
        },

        ".note": {
            marginTop: 8,
            color: "#757677",
            wordWrap: "break-word",
            whiteSpace: "pre-wrap"
        },

        ".place-edit-wrapper": {
            marginTop: 12,

            "& .footer": {
                display: "flex",
                alignItems: "center",
                gap: 8,
                marginTop: 32,

                "& .btn-wrapper": {
                    flex: 1,
                    height: 38,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: 8,
                    border: "1px solid #F4F4F4",

                    "&:hover": {
                        backgroundColor: "#F4F4F4",
                        cursor: "pointer",
                    },

                    "& .btn-wrapper-title": {
                        color: "#393939",
                        fontWeight: 600,
                        fontSize: 13,
                        marginLeft: 8
                    },
                }
            }
        }
    }
})

const confirmModalStyle = css({
    "& .ant-modal-header": {
        marginBottom: 24
    },

    "& .ant-modal-footer": {
        marginTop: 24,

        "& span": {
            cursor: 'pointer'
        },

        "& .confirm": {
            color: "#DC1D1D",
            marginLeft: 24
        }
    },
})

const editStyle = css({
    ".loading-wrapper": {
        height: 100,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",

        "& .loading": {
            WebkitAnimation: "rotate 400ms linear infinite",
            MozAnimation: "rotate 400ms linear infinite",
            animation: "rotate 400ms linear infinite"
        }
    },

    "& .detail-wrapper-body": {
        "& .edit-wrapper": {
            padding: 12,

            "& .ant-form-item-label": {
                paddingBottom: 4,

                "label": {
                    color: "#757677",

                    "&.ant-form-item-required::before": {
                        content: "\"\""
                    }
                }
            },

            "& .list-description": {
                marginTop: 30,
                marginBottom: 20
            },

            "& .footer": {
                display: "flex",
                alignItems: "center",
                gap: 8,
                marginTop: 32,

                "& .btn-wrapper": {
                    flex: 1,
                    height: 38,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: 8,
                    border: "1px solid #F4F4F4",

                    "&:hover": {
                        backgroundColor: "#F4F4F4",
                        cursor: "pointer",
                    },

                    "& .btn-wrapper-title": {
                        color: "#393939",
                        fontWeight: 600,
                        fontSize: 13,
                        marginLeft: 8
                    },
                }
            }
        },

        "& .list-item": {
            padding: "12px 12px 12px 24px",
            fontSize: 15
        }
    },

    "& .place-wrapper": {
        borderTop: "1px solid #F4F4F4",

        "& .place-wrapper-header": {
            display: "flex",
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: "8px 12px 8px 24px",
            borderBottom: "1px solid #F4F4F4",
            height: 57,

            ".title": {
                fontSize: 15,
            },

            "& .add-new": {
                padding: "10px 8px",
                color: "#2B8FFE",
                fontSize: 14,
                fontWeight: 600,

                "&:hover": {
                    backgroundColor: "#EAF4FF",
                    cursor: 'pointer',
                    borderRadius: 8
                },

                "&.disabled": {
                    color: "#C6C6C6",
                    pointerEvents: "none"
                }
            }
        },

        "& .place-wrapper-body": {
            overflowY: "auto",
            overflowX: "hidden",
            maxHeight: "calc(100vh - 210px)",
            scrollbarWidth: "thin",
            scrollbarColor: "#E0E0E0 transparent",

            "& .place-list-wrapper": {
                ".empty-places": {
                    height: 60,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: 14,
                    color: "#757677"
                }
            },

            "& .add-place-wrapper": {
                padding: 12,
                borderTop: "1px solid #F4F4F4",

                "& .input-wrapper": {
                    flex: 1,
                    height: 48,
                    display: "flex",
                    alignItems: "center",
                    padding: "12px 8px",
                    borderRadius: 4,
                    border: "1px solid #F4F4F4",


                    "& input": {
                        height: 20,
                        width: "calc(100% - 22px)",
                        border: "none",
                        padding: 0,

                        "&::placeholder": {
                            fontSize: 15,
                            color: "#8C8C8D"
                        },

                        "&:focus": {
                            outline: "none"
                        },

                        "&:disabled": {
                            width: "100%",
                            backgroundColor: "transparent",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                        }
                    },

                    "& .loading": {
                        WebkitAnimation: "rotate 400ms linear infinite",
                        MozAnimation: "rotate 400ms linear infinite",
                        animation: "rotate 400ms linear infinite"
                    }
                }
            },

            "& .add-places-results": {
                padding: "0px 12px 12px",

                ".search-list-item": {
                    display: "flex",
                    padding: 6,
                    fontSize: 13,

                    "&:hover": {
                        backgroundColor: "#F4F4F4",
                        cursor: "pointer",
                        borderRadius: 4
                    },

                    "&:not(:last-child)": {
                        marginBottom: 6
                    }
                }
            },

            "& .message-wrapper": {
                padding: "12px 18px",
                fontSize: 14,
                borderTop: "1px solid #F4F4F4",

                "& .message-title": {
                    fontWeight: 600,
                    color: "#393939"
                },

                "& .message-description": {
                    fontWeight: 400,
                    color: "#757677"
                }
            }
        }
    }
})

const handleEnterKeyDown = (e) => {
    if (e.key === 'Enter') e.preventDefault(); // Evita a nova linha
};

const handleClickOutside = () => {
    const dropdowns = document.querySelectorAll('.list-options, .place-options');
    dropdowns.forEach((dropdown) => {
        if (dropdown.classList.contains('show')) {
            dropdown.classList.remove('show');
        }
    });
};

const renderDots = (show) => {
    const dotsElem = window.document.querySelectorAll(".list-options-btn,.place-options-btn");
    dotsElem.forEach(d => show ? d.classList.remove("hide") : d.classList.add("hide"));
}

const removePlaceMarkers = (map) => {
    if (map.getSource(placesSourceName)) {
        if (map.getLayer(placesLayerName)) map.removeLayer(placesLayerName);
        map.removeSource(placesSourceName);
    }
}

const fitPlaces = (map, places) => {
    // Fit map on markers
    const bounds = places.map(p => p["location"]).reduce((bounds, coord) => {
        return bounds.extend(coord);
    }, new LngLatBounds(places[0]["location"]));
    map.fitBounds(bounds, { padding: 100 });
}

const addPlaceMarkers = (map, places) => {
    removePlaceMarkers(map);

    if (places.length) {
        // Add source
        map.addSource(placesSourceName, {
            "type": "geojson",
            "data": {
                "type": "FeatureCollection",
                "features": places.map((place) => {
                    return {
                        "type": "Feature",
                        "geometry": {
                            "type": "Point",
                            "coordinates": [place["location"].lng, place["location"].lat]
                        },
                        "properties": {
                            "name": place["name"]
                        }
                    }
                })
            }
        });

        // Add layer
        map.addLayer({
            "id": placesLayerName,
            "type": "symbol",
            "source": placesSourceName,
            "layout": {
                "icon-image": "custom-marker",
                "text-size": 14,
                "icon-size": 1,
                "text-field": ["get", "name"],
                "text-anchor": "left",
                "icon-anchor": "bottom",
                "text-offset": [1.2, -1.5],
            },
            "paint": {
                "text-color": "#000000",
                "text-halo-color": "#FFFFFF",
                "text-halo-width": 1.8
            }
        });

        // Persist layer
        map.persistLayers([placesLayerName]);

        // Fit map on markers
        fitPlaces(map, places);
    }
}

const ConfirmationModal = ({ open, title, description, cancelFn, confirmationFn, confirmLabel }) => {
    return (
        <Modal open={open} maskClosable={false} className={confirmModalStyle} onCancel={cancelFn} width={398}
            title={title} centered footer={
                <div>
                    <span onClick={cancelFn}>Cancel</span>
                    <span className='confirm' onClick={confirmationFn}>{confirmLabel || "Delete"}</span>
                </div>
            }>
            <span>{description}</span>
        </Modal>
    )
}

const ShareModal = ({ open, list, cancelFn, sharingMode }) => {
    const [privacyStatus, setPrivacyStatus] = useState(list.privacy_status.toLowerCase());
    const [isLoading, setIsLoading] = useState(false);
    const [shareHash, setShareHash] = useState(false);
    const [editorMode, setEditorMode] = useState(list.is_editors_allowed);
    const [linkMode, setLinkMode] = useState(null);
    const [removeEditor, setRemoveEditor] = useState(null);
    const [listData, setListData] = useState(list);

    const onPrivacyChange = (privacy) => {
        setIsLoading(true);
        updateList(listData.id, { "privacy_status": privacy })
            .then(() => {
                renderToast("Changes successfully updated.")
                setPrivacyStatus(privacy);
            })
            .catch(() => {
                renderToast("It wasn't possible to save your changes at the moment. Try again later.")
            })
            .finally(() => window.setTimeout(() => setIsLoading(false), 300));
    }

    const onEditorChange = (allowed) => {
        setIsLoading(true);
        updateList(listData.id, { "is_editors_allowed": allowed })
            .then(() => {
                renderToast("Changes successfully updated.");
                setEditorMode(allowed);
                getListData();
            })
            .catch(() => {
                renderToast("It wasn't possible to save your changes at the moment. Try again later.")
            })
            .finally(() => window.setTimeout(() => setIsLoading(false), 300));
    }

    const generateLink = (mode, fromSharing) => {
        setIsLoading(true);

        return inviteList({ "place_list_id": listData.id, "mode": mode })
            .then((res) => {
                setShareHash(res.id);
                setLinkMode(mode);
            })
            .catch(() => {
                renderToast("It wasn't possible to generate link at the moment. Try again later.")
                if (fromSharing) cancelFn();
            })
            .finally(() => window.setTimeout(() => setIsLoading(false), 300));

    };

    const deleteEditorFn = () => {
        deleteEditor(listData.id, removeEditor)
            .then(() => {
                renderToast("Editor removed.");
                getListData();
            })
            .catch(() => {
                renderToast("It wasn't possible to save your changes at the moment. Try again later.")
            })
            .finally(() => setRemoveEditor(null));
    }

    const getListData = () => {
        setIsLoading(true);
        getList(listData.id)
            .then((list) => {
                setListData(list);
            })
            .finally(() => window.setTimeout(() => setIsLoading(false), 300));
    }

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text);
        renderToast("Copied.")
    };

    useEffect(() => {
        if (open && sharingMode) generateLink(sharingMode, true)
    }, [open, sharingMode])

    const title = !shareHash ? "Share" : (linkMode === "view" ? "Share link to view" : "Share link to edit");

    return (
        <>
            <Modal open={!removeEditor && open} title={title} maskClosable={false} className={`${shareModalStyle} ${isLoading && "hidden-header"}`} onCancel={cancelFn} width={488} centered footer={null}>
                {isLoading ?
                    <div className='loading-wrapper'>
                        <SvgIcon name="ic_loading" size={24} className="loading" />
                    </div>
                    :
                    (
                        shareHash ?
                            <div className="share-link-wrapper">
                                <div className="share-link-header">
                                    <SvgIcon name={listData.is_protected ? "ic_list_lock" : "ic_list"} size={24} color="#393939" className="list-icon" />
                                    <div className="description">
                                        <div className="title">{listData.name}</div>
                                        <div className="sub-title">from {listData.owner.first_name} {listData.owner.last_name}</div>
                                    </div>
                                </div>
                                <div className="share-link-box">
                                    <div className="share-url">{window.location.origin}{window.location.pathname}?share={shareHash}{window.location.hash}</div>
                                    <div className="nmapsgl-ctrl-directions-copy-icon nmapsgl-pointer" onClick={() => { copyToClipboard(`${window.location.origin}${window.location.pathname}?share=${shareHash}${window.location.hash}`); }}> </div>
                                </div>
                            </div>
                            :
                            <div>
                                <Radio.Group onChange={(e) => onPrivacyChange(e.target.value)} value={privacyStatus}>
                                    <Space direction="vertical">
                                        <Radio value={"private"}>
                                            <div className="share-option-wrapper">
                                                <div className="share-option-description">
                                                    <div className="title">Private</div>
                                                    <div>Only visible by you</div>
                                                </div>
                                            </div>
                                        </Radio>
                                        <Radio value={"shared"}>
                                            <div className="share-option-wrapper">
                                                <div className="share-option-description">
                                                    <div className="title">Shared</div>
                                                    <div>Anyone with this link can view it.</div>
                                                </div>
                                                <Button disabled={privacyStatus !== "shared"} className="link-btn" onClick={() => generateLink("view")}>
                                                    <SvgIcon name="ic_link" size={16} className="link" />
                                                    {editorMode ? "Send link to view" : "Send link"}
                                                </Button>
                                            </div>
                                        </Radio>
                                    </Space>
                                </Radio.Group>
                                {privacyStatus === "shared" &&
                                    <div className="edit-wrapper">
                                        <div className="edit-wrapper-header">
                                            <div className="title">Edit options</div>
                                            <div className="switch-wrapper">
                                                <div>Anyone invited can edit and invite more people.</div>
                                                <Switch value={editorMode} onChange={(checked) => onEditorChange(checked)} />
                                            </div>
                                        </div>
                                        {editorMode &&
                                            <div className="editors-wrapper">
                                                <div className="header">
                                                    <div className="title">Editors</div>
                                                    <Button className="link-btn" onClick={() => generateLink("edit")}>
                                                        <SvgIcon name="ic_link" size={16} className="link" />
                                                        Send link to edit
                                                    </Button>
                                                </div>
                                                <div className="editors-list">
                                                    <div className="editors-list-item">
                                                        <div className="editor-description-wrapper">
                                                            {
                                                                listData.owner?.picture ?
                                                                    <div className="avatar" style={{ backgroundImage: `url(${listData.owner.picture})` }} /> :
                                                                    <SvgIcon name="ic_avatar" size={40} />
                                                            }
                                                            <div className="editor-label">
                                                                <div>{listData.owner && `${listData.owner.first_name} ${listData.owner.last_name}`}</div>
                                                                <div className="sub-title">Owner</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {listData.editors?.map(editor => (
                                                        <div className="editors-list-item" key={editor} >
                                                            <div className="editor-description-wrapper">
                                                                {
                                                                    editor.picture ?
                                                                        <div className="avatar" style={{ backgroundImage: `url(${editor.picture})` }} /> :
                                                                        <SvgIcon name="ic_avatar" size={40} />
                                                                }
                                                                <div className="editor-label">
                                                                    <div>{`${editor.first_name} ${editor.last_name}`}</div>
                                                                </div>
                                                            </div>
                                                            <SvgIcon name="ic_logout" size={16} className="remove" onClick={() => setRemoveEditor(editor.id)} />
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        }
                                    </div>
                                }
                            </div>
                    )
                }
            </Modal >
            <ConfirmationModal open={removeEditor} title="Remove editor" confirmationFn={deleteEditorFn} cancelFn={() => setRemoveEditor(null)}
                description="Are you sure you want to remove this user from your list? " confirmLabel="Remove" />
        </>
    )
}

const ListItem = ({ list, backfn, addPlace, setEditMode, callback, sharingMode }) => {
    const [openDeleteList, setOpenDeleteList] = useState(false);
    const [openLeaveList, setOpenLeaveList] = useState(false);
    const [openShareList, setOpenShareList] = useState(false);
    const [shareMode, setShareMode] = useState(sharingMode);
    const auth = useAuth();

    const showOptions = (e) => {
        e.stopPropagation();
        handleClickOutside();
        const dropdown = document.querySelector('.list-options');
        if (dropdown) dropdown.classList.add('show');
    }

    const deleteListFn = () => {
        deleteList(list.id)
            .then(() => {
                renderToast(`"${list.name}" list was removed.`);
                backfn();
            })
            .catch(() => {
                setOpenDeleteList(false);
                renderToast("It wasn't possible to remove this list at the moment.");
            })
    };

    const leaveListFn = () => {
        deleteEditor(list.id, auth.user?.profile.sub)
            .then(() => {
                renderToast("You were successfully removed from the list.");
                backfn();
            })
            .catch(() => {
                renderToast("It wasn't possible to save your changes at the moment. Try again later.")
            })
            .finally(() => setOpenLeaveList(false))
    }

    const shareListFn = () => {
        setOpenShareList(false);
        callback()
    }

    return (
        <div className={listItemStyle}>
            <div className="list-item-detail" key={list.id}>
                <div className='description-wrapper'>
                    <div className='description'>
                        <span className='name'>{list.name}</span>
                        <span className='places'>
                            {list.owner?.id !== auth.user?.profile.sub && list.owner &&
                                <>
                                    <Avatar.Group size={20}
                                        max={{ count: 2, style: { color: '#474849', backgroundColor: '#F4F4F4' } }}
                                    >
                                        <Avatar src={list.owner?.picture} style={{ backgroundColor: '#c0c0c0' }} icon={<UserOutlined />} />
                                        {list.editors?.map((editor, i) =>
                                            <Avatar key={`editor-${i}`} src={editor.picture} style={{ backgroundColor: '#c0c0c0' }} icon={<UserOutlined />} />
                                        )}
                                    </Avatar.Group>
                                    <span className='owner'>{list.owner?.first_name} {list.owner?.last_name} -</span>
                                </>
                            }
                            <span>{list.privacy_status} - {list.items?.length || 0} places</span>
                        </span>
                    </div>
                </div>
                {
                    (list.is_protected || sharingMode === "view" && list.owner?.id !== auth.user?.profile.sub) ?
                        <SvgIcon name="ic_share" size={24} color="#393939" className="list-options-btn" onClick={() => { setOpenShareList(true); setShareMode("view") }} />
                        :
                        <SvgIcon name="ic_dots" size={24} color="#393939" className={`list-options-btn ${addPlace && "hide"}`} onClick={showOptions} />
                }
                <div id={list.id} className='list-options'>
                    {(list.owner?.id !== auth.user?.profile.sub) ?
                        <>
                            <li onClick={() => { setOpenShareList(true); setShareMode("view") }}>Share link to view</li>
                            <li onClick={() => { setOpenShareList(true); setShareMode("edit") }}>Share link to edit</li>
                            <li onClick={() => { setOpenLeaveList(true) }}>Leave list</li>
                        </>
                        :
                        <>
                            <li onClick={() => setEditMode(true)}>Edit</li>
                            <li onClick={() => setOpenShareList(true)}>Share</li>
                            {!list.is_protected && <li onClick={() => setOpenDeleteList(true)}>Delete</li>}
                        </>
                    }
                </div>
            </div>
            {list.description && <div className="list-description">{list.description}</div>}
            <ConfirmationModal open={openDeleteList} title="Delete List" confirmationFn={deleteListFn} cancelFn={() => setOpenDeleteList(false)}
                description="Are you sure you want to delete this list? Every places associated will be removed." />
            <ConfirmationModal open={openLeaveList} title="Leave list" confirmationFn={leaveListFn} cancelFn={() => setOpenLeaveList(false)}
                description="Are you sure you want to be removed from this list? To join again you’ll need a new invitation." confirmLabel="Leave" />
            <ShareModal open={openShareList} list={list} cancelFn={shareListFn} sharingMode={shareMode} />
        </div>
    )
}

const PlaceItem = ({ list_id, place, addPlace, callback, sharingMode, map, places }) => {
    const [editPlace, setEditPlace] = useState(false);
    const [openSharePlace, setOpenSharePlace] = useState(false);
    const [openDeletePlace, setOpenDeletePlace] = useState(false);
    const [openCancelModal, setOpenCancelModal] = useState(false);
    const [text, setText] = useState(place.note);

    const showOptions = (e, id) => {
        e.stopPropagation();
        handleClickOutside();
        const options = document.getElementById(id);
        if (options) {
            const { top, left } = e.target.getBoundingClientRect();
            options.style.top = `${top}px`;
            options.style.left = `${left}px`;
            options.classList.add('show');
        }
    }

    const deletePlaceFn = () => {
        deletePlace(list_id, place._id)
            .then(() => {
                renderToast("Place was removed.");
                callback();
            })
            .catch(() => renderToast("It wasn't possible to remove this place at the moment."))
            .finally(() => setOpenDeletePlace(false))
    };

    const savePlace = () => {
        updatePlace(list_id, place._id, { note: text })
            .then(() => {
                renderToast("Place info updated.")
                setEditPlace(false);
                callback();
            })
            .catch(() => renderToast("It wasn't possible to save your changes at the moment. Try again later."))
    };

    const showPlaceDetail = (e) => {
        const dropdown = document.querySelectorAll('.list-options, .place-options');
        const hasShowClass = Array.from(dropdown).some(element => element.classList.contains('show'));
        // Don't show place detail if dropdown is open
        if (hasShowClass) return;

        // Add new selected
        const elem = window.document.getElementById(`place-${place._id}`);
        if (elem) {
            if (elem.classList.contains("selected")) {
                // Remove selected
                elem.classList.remove("selected");
                //Fit to bounds
                fitPlaces(map, places);
            } else {
                // Remove previous selected
                const selected = window.document.getElementsByClassName("selected");
                if (selected.length) Array.from(selected).forEach(s => s.classList.remove("selected"));

                // Add selected
                elem.classList.add("selected");
                // Fly to place
                map.flyTo({ center: [place.location.lng, place.location.lat], zoom: 16 });
            }
        }
    }

    const discard = () => {
        setEditPlace(false);
        setOpenCancelModal(false);
    }

    useEffect(() => {
        renderDots(!editPlace);
    }, [editPlace]);

    return (
        <div className={placeItemStyle}>
            <div className={`place-item-wrapper ${editPlace && "edit"}`} onClick={() => !editPlace && showPlaceDetail()} id={`place-${place._id}`}>
                <div className="place-item">
                    <div className="description-wrapper">
                        <div className="nmapsgl-ctrl-directions-poi-icon"></div>
                        <div className="description">
                            <p className="title">{place.name}</p>
                            <p className="subtitle">{getFormatedAddress(place)}</p>
                        </div>
                    </div>
                    {
                        !sharingMode &&
                        <>
                            <SvgIcon name="ic_dots" size={24} color="#393939" className={`place-options-btn ${editPlace || addPlace ? "hide" : ""}`} onClick={(e) => showOptions(e, place._id)} />
                            <div id={place._id} className='place-options'>
                                <li onClick={() => setEditPlace(true)}>Edit</li>
                                <li onClick={() => setOpenSharePlace(true)}>Share</li>
                                <li onClick={() => setOpenDeletePlace(true)}>Delete</li>
                            </div>
                        </>
                    }
                </div>
                {(place.note && !editPlace) && <div className="note">Note: {place.note}</div>}
                {editPlace &&
                    <div className="place-edit-wrapper">
                        <TextArea showCount maxLength={300} placeholder='Add note' value={text} onChange={(e) => setText(e.target.value)} autoSize={true} />
                        <div className="footer">
                            <div className='btn-wrapper' onClick={savePlace}>
                                <SvgIcon name="ic_check" size={16} color="#2B8FFF" />
                                <div className='btn-wrapper-title'>Save</div>
                            </div>
                            <div className='btn-wrapper' onClick={() => setOpenCancelModal(true)}>
                                <SvgIcon name="ic_close" size={16} color="#DC1D1D" />
                                <div className='btn-wrapper-title'>Cancel</div>
                            </div>
                        </div>
                    </div>
                }
            </div>
            <Share open={openSharePlace} closefn={() => setOpenSharePlace(false)} shareType="place" shareData={{ "payload_type": "place", "payload": omit(place, "_id") }} />
            <ConfirmationModal open={openDeletePlace} title="Delete Place" confirmationFn={deletePlaceFn} cancelFn={() => setOpenDeletePlace(false)}
                description="Are you sure you want to delete this place? It will disappear from the list and any notes associated with it will be lost." />
            <ConfirmationModal open={openCancelModal} title="Discard information" confirmationFn={discard} cancelFn={() => setOpenCancelModal(false)}
                description="Are you sure you want to discard all the information added until this point?" confirmLabel="Discard" />
        </div>
    )
}

export const DetailList = ({ map, list, backfn, closefn, sharingMode }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isSearchLoading, setIsSearchLoading] = useState(false);
    const [listData, setListData] = useState(null);
    const [editMode, setEditMode] = useState(false);
    const [addPlace, setAddPlace] = useState(false);
    const [openCancelModal, setOpenCancelModal] = useState(false);
    const [form] = Form.useForm();
    const [inputText, setInputText] = useState("");
    const [results, setResults] = useState([]);
    const [error, setError] = useState(null);
    const auth = useAuth();

    const getData = useCallback(() => {
        setIsLoading(true);
        getList(list.id)
            .then((data) => {
                setListData(data);
                addPlaceMarkers(map, data.items)
            })
            .finally(() => window.setTimeout(() => setIsLoading(false), 300));
    }, []);

    useEffect(getData, [getData]);

    useEffect(() => {
        renderDots(!addPlace);
    }, [addPlace]);

    const save = () => {
        updateList(listData.id, form.getFieldsValue())
            .then(() => {
                renderToast("List info updated.")
                setEditMode(false);
                getData();
            })
            .catch(() => {
                renderToast("It wasn't possible to save your changes at the moment. Try again later.")
            })
    };

    const discard = () => {
        form.resetFields();
        setOpenCancelModal(false);
        setEditMode(false);
    }

    const handleChange = (e) => {
        const text = e.target.value;
        setInputText(text);
        if (!text) {
            setError(null);
            setResults([]);
        }
    }

    const keydown = () => {
        if (inputText) {
            setIsSearchLoading(true);
            setError(null);
            setResults([]);

            genericSearch({ search_text: inputText, ref_location: map.getCenter(), max_results: 5 })
                .then((res) => {
                    if (res.length === 0) {
                        setError({ title: "We couldn’t find any results", description: "At this time there's no results available for this area." })
                    } else {
                        setResults(res);
                    }
                })
                .catch(() => setError({ title: "There was an error reaching the serve", description: "Please try again" }))
                .finally(() => setIsSearchLoading(false));
        }
    }

    const addResult = (result) => {
        createPlace(listData.id, result)
            .then(() => {
                renderToast("New place was added.")
            })
            .catch(() => {
                renderToast("It wasn't possible to add this place at the moment.")
            })
            .finally(() => {
                setAddPlace(false);
                setResults([]);
                setInputText("");
                getData();
            })
    }

    const checkAddPlace = (e) => {
        if (addPlace && !e.target?.classList.contains("input")) {
            setAddPlace(false);
            setError(null);
            setResults([]);
            setInputText("");
        }
    }

    return (
        <>
            <div className={editStyle} onClick={(e) => { e.stopPropagation(); handleClickOutside(); checkAddPlace(e); }}>
                <MenuHeader title={editMode ? "Edit List" : list.name} backfn={editMode ? discard : () => { removePlaceMarkers(map); backfn(); }} closefn={() => { removePlaceMarkers(map); closefn(); }} />
                {isLoading ?
                    <div className='loading-wrapper'>
                        <SvgIcon name="ic_loading" size={24} className="loading" />
                    </div>
                    :
                    <div className='detail-wrapper-body'>
                        {editMode ?
                            <Form form={form} name="control-hooks" className='edit-wrapper' layout="vertical"
                                initialValues={{ name: listData?.name || list.name, description: listData?.description || list.description }}>
                                <Form.Item name="name" label="List name" rules={[{ required: true }]}>
                                    <TextArea showCount maxLength={50} style={{ resize: 'none' }} placeholder='List name' onKeyDown={handleEnterKeyDown} />
                                </Form.Item>
                                <Form.Item name="description" label="List description">
                                    <TextArea showCount maxLength={300} style={{ height: 166, resize: 'none' }} placeholder='Add description' />
                                </Form.Item>
                                <div className='footer'>
                                    <div className='btn-wrapper' onClick={save}>
                                        <SvgIcon name="ic_check" size={16} color="#2B8FFF" />
                                        <div className='btn-wrapper-title'>Save</div>
                                    </div>
                                    <div className='btn-wrapper' onClick={() => setOpenCancelModal(true)}>
                                        <SvgIcon name="ic_close" size={16} color="#DC1D1D" />
                                        <div className='btn-wrapper-title'>Cancel</div>
                                    </div>
                                </div>
                            </Form>
                            :
                            <ListItem list={listData || list} backfn={backfn} addPlace={addPlace} setEditMode={setEditMode} callback={getData} sharingMode={sharingMode} />
                        }
                        {!editMode &&
                            <div className='place-wrapper'>
                                <div className='place-wrapper-header'>
                                    <div className="title">Places</div>
                                    {((listData?.owner?.id === auth.user?.profile.sub && !editMode) || sharingMode === "edit") && <div className={`add-new ${addPlace && "disabled"}`} onClick={() => setAddPlace(true)}>Add new place</div>}
                                </div>
                                <div className='place-wrapper-body'>
                                    <div className="place-list-wrapper">
                                        {
                                            !addPlace && listData?.items?.length === 0 ?
                                                <div className="empty-places">
                                                    Add your first place and start exploring.
                                                </div> :
                                                listData?.items?.map(p => <PlaceItem key={p._id} list_id={listData.id} place={p} addPlace={addPlace} callback={getData} sharingMode={sharingMode} map={map} closefn={closefn} places={listData?.items} />)
                                        }
                                    </div>
                                    {addPlace &&
                                        <>
                                            <div className="add-place-wrapper" onClick={(e) => e.stopPropagation()}>
                                                <div className="input-wrapper">
                                                    <input className="input" value={inputText} placeholder='Search and add a place'
                                                        onKeyDown={e => e.key === "Enter" && keydown()} onChange={handleChange}
                                                        disabled={isSearchLoading}
                                                    />
                                                    {isSearchLoading && <SvgIcon name="ic_loading" size={16} className="loading" />}
                                                </div>
                                            </div>
                                            {(results.length !== 0) &&
                                                <div className="add-places-results" onClick={(e) => e.stopPropagation()}>
                                                    {results.map((r, idx) => (
                                                        <div key={`place-${idx}`} className="search-list-item" onClick={() => addResult(r)}>
                                                            <div className="nmapsgl-ctrl-directions-poi-icon"></div>
                                                            <div className="nmapsgl-ctrl-directions-search-description">
                                                                <p className="title">{r.name}</p>
                                                                <p className="subtitle">{getFormatedAddress(r)}</p>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>}
                                            {error &&
                                                <div className="message-wrapper" onClick={(e) => e.stopPropagation()}>
                                                    <div className="message-title">{error.title}</div>
                                                    <div className="message-description">{error.description}</div>
                                                </div>}
                                        </>
                                    }
                                </div>
                            </div>
                        }
                    </div>
                }
            </div>
            <ConfirmationModal open={openCancelModal} title="Discard information" confirmationFn={discard} cancelFn={() => setOpenCancelModal(false)}
                description="Are you sure you want to discard all the information added until this point?" confirmLabel="Discard" />
        </>
    )
}

const SaveTabs = ({ showDetailListFn }) => {
    const [activeTab, setActiveTab] = useState("lists");
    const [isLoading, setIsLoading] = useState(false);
    const [lists, setLists] = useState([]);
    const [text, setText] = useState("");
    const [openNewList, setOpenNewList] = useState(false);

    const getData = useCallback(() => {
        setIsLoading(true);
        getLists()
            .then((data) => setLists(data))
            .finally(() => window.setTimeout(() => setIsLoading(false), 300));
    }, []);

    useEffect(getData, [getData]);

    const onChange = (e) => {
        setText(e.target.value);
        const createListBtn = window.document.getElementById("create-list-btn");
        if (createListBtn)
            e.target.value ? createListBtn.classList.add("active") : createListBtn.classList.remove("active");
    };

    const createListFn = () => {
        if (text) {
            createList({ "name": text })
                .then(() => renderToast(`"${text}" list was created.`))
                .catch(() => renderToast("It wasn't possible to create this list at the moment."))
                .finally(() => {
                    setText("");
                    setOpenNewList(false);
                    getData();
                })
        }
    };

    const items = [
        {
            key: "lists",
            label: "Lists",
            children: (
                <>
                    {isLoading ?
                        <div className='loading-wrapper'>
                            <SvgIcon name="ic_loading" size={24} className="loading" />
                        </div>
                        :
                        <div className={listsStyle}>
                            <div className='header'>
                                <span className='label'>Your lists</span>
                                <span className='new-list' onClick={() => setOpenNewList(true)}>Add new list</span>
                            </div>
                            <div className="list-wrapper">
                                {
                                    lists.length === 0 ?
                                        <div className="empty-lists">
                                            Add your first list and start exploring.
                                        </div>
                                        :
                                        lists?.map((l, i) => (
                                            <div className="list-item" key={l.id} onClick={() => showDetailListFn(l)}>
                                                <div className='description-wrapper'>
                                                    <SvgIcon name={l.is_protected ? "ic_list_lock" : "ic_list"} size={24} color="#393939" className="list-icon" />
                                                    <div className='description'>
                                                        <span className='name'>{l.name}</span>
                                                        <span className='places'>{l.privacy_status} - {l.items_count || 0} places</span>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                }
                            </div>
                        </div >
                    }
                </>
            ),
        },
        {
            key: "tags",
            label: "Tags",
            children: (
                <div>Tags will be display here!</div>
            ),
        },
    ];

    return (
        <>
            <Tabs activeKey={activeTab} defaultActiveKey="lists" className={tabsStyle} onChange={setActiveTab} items={items} />
            <Modal open={openNewList} maskClosable={false} className={modalStyle} onCancel={() => setOpenNewList(false)} width={446} title="New List"
                centered footer={
                    <div id="create-list-btn" className='create-list' onClick={createListFn}>Create list</div>
                }>
                <TextArea showCount value={text} onChange={onChange} onKeyDown={handleEnterKeyDown} maxLength={50} style={{ height: 36, resize: 'none' }} placeholder='List name' />
            </Modal>
        </>
    );
}

export const Lists = ({ map, closefn, setShowSaved }) => {
    const [currentListOption, setCurrentListOption] = useState(null);
    const [showListDetail, setShowListDetail] = useState(false);

    const backListOptionFn = () => {
        setShowListDetail(false);
        setCurrentListOption(null);
        setShowSaved(true);
    }

    const showDetailListFn = (l) => {
        setShowSaved(false);
        setCurrentListOption(l);
        setShowListDetail(true);
    }

    return (
        <>
            {showListDetail ?
                <DetailList map={map} list={currentListOption} backfn={backListOptionFn} closefn={closefn} />
                :
                <SaveTabs showDetailListFn={showDetailListFn} />
            }
        </>
    );
}