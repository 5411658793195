import { css } from "@emotion/css";
import { DirectionControl } from "@ndrive/nmaps-gl";
import React, { useEffect, useState } from 'react';
import { SvgIcon } from "../../icons";
import { Share } from "../menu/components/share";

const actionsStyle = css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: "8px 12px 16px",
    marginTop: "-16px",
    gap: 8,

    "&> *": {
        flex: 1
    },

    "& .action-btn": {
        padding: "12px 4px 8px",
        fontSize: 14,
        borderRadius: 8,
        border: "1px solid #F4F4F4",
        color: "#393939",
        fontWeight: 600,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",

        "&:hover": {
            backgroundColor: "#F4F4F4"
        },

        "& .icon": {
            marginRight: 8
        },

        "& .label": {
            height: 18,
        }
    }
})

const ShareRouting = ({ currentRouting }) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <>
            <div className="action-btn" onClick={() => setIsOpen(true)}>
                <SvgIcon name="ic_share_small" className="icon" size={16} />
                <div className="label">Share trip info</div>
            </div>
            <Share open={isOpen} closefn={() => setIsOpen(false)} shareType="place" shareData={{ "payload_type": "route", "payload": currentRouting }} />
        </>
    )
}


export const RoutingActions = ({ map }) => {
    const directionControl = map?._controls?.find(c => c instanceof DirectionControl);
    const [currentRouting, setCurrentRouting] = useState(null);

    useEffect(() => {
        directionControl.eventTarget.addEventListener("changeDirectionsValue", () => {
            // Transform request
            let currentRoutingData = directionControl.currentRouting;
            if (directionControl.currentRouting)
                currentRoutingData = {
                    ...directionControl.currentRouting,
                    waypoints: directionControl.currentRouting?.waypoints.map(wp => {
                        return {
                            "name": wp.name,
                            "formatted_address": wp.formatted_address,
                            "lat": wp.location.lat,
                            "lng": wp.location.lng
                        }
                    })
                };
            setCurrentRouting(currentRoutingData);
        })
    }, [])


    return (
        <div className={actionsStyle}>
            {currentRouting && <ShareRouting currentRouting={currentRouting} />}
        </div>
    );
}
