import { css } from '@emotion/css';
import React from 'react';
import { SvgIcon } from '../../../icons';

const headerStyle = css({
    display: 'flex',
    height: 48,
    alignItems: 'center',
    borderBottom: '1px solid #F4F4F4',
    justifyContent: 'space-between',

    "& .wrapper": {
        display: 'flex',
        alignItems: 'center',
        flex: 1,
        width: "calc(100% - 60px)",

        "& .back": {
            borderRight: '1px solid #F4F4F4',
            cursor: 'pointer',
            height: 48,
            width: 48,
            padding: 12
        },

        "& .title": {
            fontSize: 16,
            padding: "14px 8px",
            color: "#393939",
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
        },
    },

    "& .close": {
        margin: "6px 12px",
        width: 36,
        height: 36,
        padding: 6,

        "&:hover": {
            cursor: "pointer",
            backgroundColor: "#F4F4F4",
            borderRadius: 4
        }
    },
});

export const MenuHeader = ({ title, backfn, closefn }) => {

    return (
        <div className={headerStyle}>
            <div className="wrapper">
                <SvgIcon name="ic_back" size={24} color="#393939" className="back" onClick={backfn} />
                <div className="title">{title}</div>
            </div>
            {closefn && <SvgIcon name="ic_close" size={24} color="#393939" className="close" onClick={closefn} />}
        </div>
    );
}