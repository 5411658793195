import axios from "axios";
import { User } from "oidc-client-ts";
import { list_privacy_status } from "../constants";

const renameField = (obj, oldKey, newKey) => {
    if (obj[oldKey] !== undefined) {
        obj[newKey] = obj[oldKey];
        delete obj[oldKey];
    }
};

const fieldTransformer = {
    "privacy_status": (value) => list_privacy_status[value],
};

const client = axios.create({
    baseURL: process.env.DES_URL,
    headers: { "Content-type": "application/json" },
    paramsSerializer: { indexes: null },
    transformResponse: (data) => {
        if (!data) return data;
        const parsedData = JSON.parse(data, (key, value) => fieldTransformer[key] ? fieldTransformer[key](value) : value);
        (Array.isArray(parsedData)) ? parsedData.forEach(item => renameField(item, "_id", "id")) : renameField(parsedData, "_id", "id");
        return parsedData;
    },
});

function getUser() {
    const oidcStorage = localStorage.getItem(`oidc.user:${process.env.KEYCLOAK_AUTHORITY}:${process.env.KEYCLOAK_CLIENT_ID}`)
    if (!oidcStorage) return null;
    return User.fromStorageString(oidcStorage);
}

client.interceptors.request.use(
    config => {
        const user = getUser();
        const token = user?.access_token;
        if (token) config.headers.Authorization = `Bearer ${token}`;
        return Promise.resolve(config);
    },
    e => Promise.reject(e)
);

// Lists
export function getLists(params) {
    return client.get("/lists/", params)
        .then(r => r.data);
}

export function getList(id) {
    return client.get(`/lists/${id}`)
        .then(r => r.data);
}

export function createList(data) {
    return client.post("/lists/", data)
        .then(r => r.data);
}

export function updateList(id, data) {
    return client.patch(`/lists/${id}`, data);
}

export function deleteList(id) {
    return client.delete(`/lists/${id}`)
        .then(r => r.data)
}

export function inviteList(data) {
    return client.post("/lists/invite", data)
        .then(r => r.data);
}

export function getInvite(hash) {
    return client.get(`/lists/invite/${hash}`)
        .then(r => r.data);
}

export function acceptInvite(id) {
    return client.put(`/lists/invite/${id}/accept`)
        .then(r => r.data);
}

export function deleteEditor(list_id, editor_id) {
    return client.delete(`/lists/${list_id}/editors/${editor_id}`)
        .then(r => r.data);
}

// Places
export function createPlace(list_id, data) {
    return client.post(`/lists/${list_id}/places`, data)
        .then(r => r.data);
}

export function updatePlace(list_id, place_id, data) {
    return client.patch(`/lists/${list_id}/places/${place_id}`, data);
}

export function deletePlace(list_id, place_id) {
    return client.delete(`/lists/${list_id}/places/${place_id}`)
        .then(r => r.data)
}

export function isPlaceInList(data) {
    return client.post("/lists/is_place_in_list", data)
        .then(r => r.data);
}

// Hashes

export function createHash(data) {
    return client.post("/hashes/", data)
        .then(r => r.data);
}

export function getHash(hash) {
    return client.get(`/hashes/${hash}`)
        .then(r => r.data);
}