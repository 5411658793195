export const homeSourceName = "home_source";
export const homeLayerName = "home_layer";
export const placesSourceName = "places_source";
export const placesLayerName = "places_layer";

export const toastDefinitions = {
    duration: 5000,
    close: true,
    gravity: "bottom",
    position: "center",
    className: "nmapsgl-toast"
}

export const list_privacy_status = {
    "private": "Private",
    "shared": "Shared"
}

// Sources/Layers names
export const resultSourceName = "result_source";
export const resultLayerName = "result_layer";
export const waypointsSourceName = "waypoints_source";
export const waypointsLayerName = "waypoints_layer";
export const routingSourceName = "routing_source";
export const routingLayerName = "routing_layer";

// Markers 
export const markerUrl = "https://cdn.ndrive.com/nmapsgl/assets/waypoint_marker.png";
export const waypointMarkerUrl = "https://cdn.ndrive.com/nmapsgl/assets/waypoint.png";