import { browserTracingIntegration, init as initSentry, replayIntegration } from "@sentry/react";
import { WebStorageStateStore } from "oidc-client-ts";
import React from 'react';
import ReactDOM from 'react-dom/client';
import { AuthProvider } from "react-oidc-context";
import App from './App';
import './index.css';

initSentry({
  dsn: process.env.SENTRY_DSN,
  integrations: [
    browserTracingIntegration(),
    replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 0.1,
  environment: process.env.ENV,
  enabled: process.env.SENTRY_ENABLED?.toLowerCase() == "true"
});

const oidcConfig = {
  authority: process.env.KEYCLOAK_AUTHORITY,
  client_id: process.env.KEYCLOAK_CLIENT_ID,
  redirect_uri: `${window.location.origin}${window.location.pathname}`,
  userStore: new WebStorageStateStore({ store: window.localStorage }),
  monitorSession: true,
  monitorAnonymousSession: false,
  revokeTokensOnSignout: true
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <AuthProvider {...oidcConfig}>
    <App />
  </AuthProvider>
);