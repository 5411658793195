import axios from "axios";
import { User } from "oidc-client-ts";

const client = axios.create({
    baseURL: process.env.KEYCLOAK_AUTHORITY,
    headers: { "Content-type": "application/json" },
    paramsSerializer: { indexes: null },
});

function getUser() {
    const oidcStorage = localStorage.getItem(`oidc.user:${process.env.KEYCLOAK_AUTHORITY}:${process.env.KEYCLOAK_CLIENT_ID}`)
    if (!oidcStorage) return null;
    return User.fromStorageString(oidcStorage);
}

client.interceptors.request.use(
    config => {
        const user = getUser();
        const token = user?.access_token;
        if (token) config.headers.Authorization = `Bearer ${token}`;
        return Promise.resolve(config);
    },
    e => Promise.reject(e)
);

export function getProfile() {
    return client.get("/account/?userProfileMetadata=false")
        .then(r => { return r.data });
}

export function updateProfile(body) {
    return client.post("/account/", body);
}