import { css } from "@emotion/css";
import React, { useEffect, useState } from 'react';
import { SvgIcon } from "../../../../icons";
import { updateProfile } from "../../../../services/account";
import { genericSearch, reverseGeocode } from "../../../../services/nmaps";
import { getFormatedAddress, renderToast } from "../../../../utils";
import { MenuHeader } from "../header";

const addMenuStyle = css({
    "& .add-home-body": {
        display: "flex",
        alignItems: "center",
        padding: 12,

        "& .input-wrapper": {
            flex: 1,
            height: 48,
            display: "flex",
            alignItems: "center",
            marginLeft: 8,
            padding: "12px 8px",
            borderRadius: 4,
            border: "1px solid #F4F4F4",


            "& input": {
                height: 20,
                width: "calc(100% - 22px)",
                border: "none",
                padding: 0,

                "&::placeholder": {
                    fontSize: 15,
                    color: "#8C8C8D"
                },

                "&:focus": {
                    outline: "none"
                },

                "&:disabled": {
                    width: "100%",
                    backgroundColor: "transparent",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                }
            },

            "& .clear": {
                width: 28,
                height: 28,
                padding: 6,

                "&:hover": {
                    cursor: "pointer",
                    backgroundColor: "#F4F4F4",
                    borderRadius: 4
                }
            },

            "& .loading": {
                WebkitAnimation: "rotate 400ms linear infinite",
                MozAnimation: "rotate 400ms linear infinite",
                animation: "rotate 400ms linear infinite"
            }
        }
    },

    "& .add-home-results": {
        padding: 6,
        borderTop: "1px solid #F4F4F4",

        "&:has(.history-list-item) .location-wrapper": {
            marginBottom: 6
        },

        ".history-list-item": {
            display: "flex",
            padding: 6,
            fontSize: 13,

            "&:hover": {
                backgroundColor: "#F4F4F4",
                cursor: "pointer",
                borderRadius: 4
            },

            "&:not(:last-child)": {
                marginBottom: 6
            }
        },

        ".search-list-item": {
            display: "flex",
            padding: 6,
            fontSize: 13,

            "&:hover": {
                backgroundColor: "#F4F4F4",
                cursor: "pointer",
                borderRadius: 4
            },

            "&:not(:last-child)": {
                marginBottom: 6
            }
        }
    },

    "& .message-wrapper": {
        padding: "12px 18px",
        fontSize: 14,
        borderTop: "1px solid #F4F4F4",

        "& .message-title": {
            fontWeight: 600,
            color: "#393939"
        },

        "& .message-description": {
            fontWeight: 400,
            color: "#757677"
        }
    },

    "& .add-home-footer": {
        display: "flex",
        alignItems: "center",
        padding: "0px 12px 12px",
        gap: 8,

        "& .btn-wrapper": {
            flex: 1,
            height: 38,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: 8,
            border: "1px solid #F4F4F4",

            "&:hover": {
                backgroundColor: "#F4F4F4",
                cursor: "pointer",
            },

            "& .btn-wrapper-title": {
                color: "#393939",
                fontWeight: 600,
                fontSize: 13,
                marginLeft: 8
            },
        }
    }
})

export const Home = ({ backfn, closefn, userProfile, map, removeHomeMarker, addHomeMarker }) => {
    const [inputText, setInputText] = useState("");
    const [showHomeHistory, setShowHomeHistory] = useState(false);
    const [showClearButton, setShowClearButton] = useState(false);
    const [showAddHome, setShowAddHome] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [results, setResults] = useState([]);
    const [error, setError] = useState(null);
    const [selectedResult, setSelectedResult] = useState(null);
    const [disabledUserLocation, setDisabledUserLocation] = useState(false);
    const historyRes = JSON.parse(sessionStorage.getItem("search-results"))?.slice(0, 5) || [];

    useEffect(() => {
        if (!navigator.geolocation) {
            setDisabledUserLocation(true);
            return;
        }

        navigator.geolocation.getCurrentPosition(
            () => { },
            (error) => {
                if (error.code === error.PERMISSION_DENIED) {
                    setDisabledUserLocation(true);
                }
            }
        );
    }, []);

    const displayDropdown = () => {
        if (!inputText) {
            setShowHomeHistory(true);
            setShowClearButton(true);
        }
    }

    const clearInput = () => {
        setInputText("");
        setResults([]);
        setError(null);
        setSelectedResult(null);
        setShowClearButton(false);
        setShowAddHome(false);
        setShowHomeHistory(true);
        removeHomeMarker();
    }

    const handleChange = (e) => {
        const value = e.target.value;
        setInputText(e.target.value);
        if (!value) {
            clearInput();
        }
    };

    const keydown = () => {
        if (inputText) {
            setShowHomeHistory(false);
            setIsLoading(true);
            setError(null);
            setResults([]);

            genericSearch({ search_text: inputText, ref_location: map.getCenter(), max_results: 5 })
                .then((res) => {
                    if (res.length === 0) {
                        setError({ title: "We couldn’t find any results", description: "At this time there's no results available for this area." })
                    } else {
                        setResults(res);
                    }
                })
                .catch(() => setError({ title: "There was an error reaching the serve", description: "Please try again" }))
                .finally(() => setIsLoading(false));
        }
    }

    const save = () => {
        const body = {
            ...userProfile,
            attributes: {
                ...userProfile.attributes,
                "formatted": [selectedResult.formatted_address.replace(/\n/g, ", ")]
            }
        };

        let toastText;

        updateProfile(body)
            .then(() => {
                toastText = 'Home address added';
            })
            .catch(() => {
                toastText = 'Error saving home address. Please try again.';
            })
            .finally(() => {
                removeHomeMarker();
                renderToast(toastText);
                backfn();
            });
    }

    const addUserLocation = () => {
        if (navigator.geolocation) {
            setShowHomeHistory(false);
            setIsLoading(true);
            setInputText("Your location");

            window.setTimeout(() => {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        setIsLoading(false);
                        setDisabledUserLocation(false);

                        reverseGeocode({ location: { lat: position.coords.latitude, lng: position.coords.longitude }, allow_unnamed: false })
                            .then((res) => {
                                if (res.length === 0) {
                                    setError({ title: "We couldn’t find any results", description: "At this time there's no results available for this area." })
                                } else {
                                    addResult(res[0]);
                                }
                            })
                            .catch(() => setError({ title: "There was an error reaching the serve", description: "Please try again" }))
                    },
                    () => {
                        setIsLoading(false);
                        setDisabledUserLocation(true);
                        clearInput();
                    })
            }, 500);
        }
    }

    const addResult = (result) => {
        setSelectedResult(result);
        setInputText(result.formatted_address?.replace(/\n/g, ", "));
        setShowClearButton(false);
        setShowHomeHistory(false);
        setResults([]);
        setShowAddHome(true);
        addHomeMarker(result);
    }

    return (
        <div className={addMenuStyle} onClick={(e) => e.stopPropagation()}>
            <MenuHeader title="Edit home address" backfn={() => { removeHomeMarker(); backfn() }} closefn={() => { removeHomeMarker(); closefn() }} />
            <div className='add-home-body'>
                <SvgIcon name="ic_home" size={24} color="#2B8FFF" />
                <div className="input-wrapper">
                    <input className="input" value={inputText} placeholder='Add home location' onClick={displayDropdown}
                        onKeyDown={e => e.key === "Enter" && keydown()} onChange={handleChange} disabled={isLoading || selectedResult != null} />
                    {(!isLoading && showClearButton) && <SvgIcon name="ic_close" size={16} className="clear" onClick={clearInput} />}
                    {isLoading && <SvgIcon name="ic_loading" size={16} className="loading" />}
                </div>
            </div>
            {showHomeHistory &&
                <div className="add-home-results">
                    <div className={`location-wrapper ${disabledUserLocation && " disabled"} ${isLoading && " loading"}`} onClick={() => !disabledUserLocation && addUserLocation()}>
                        <div className="current-location"></div>
                        <div>Your location</div>
                    </div>
                    {historyRes.map((s, idx) => (
                        <div key={`history-${idx}`} className="history-list-item" onClick={() => addResult(s.value)} >
                            <div className="nmapsgl-ctrl-directions-history-icon"></div>
                            <div className="nmapsgl-ctrl-directions-history-description">
                                <p className="title">{s.value.name}</p>
                                <p className="subtitle">{getFormatedAddress(s.value)}</p>
                            </div>
                        </div>
                    )
                    )}
                </div>
            }
            {
                (results.length !== 0) &&
                <div className="add-home-results">
                    {results.map((r, idx) => (
                        <div key={`search-${idx}`} className="search-list-item" onClick={() => addResult(r)}>
                            <div className="nmapsgl-ctrl-directions-poi-icon"></div>
                            <div className="nmapsgl-ctrl-directions-search-description">
                                <p className="title">{r.name}</p>
                                <p className="subtitle">{getFormatedAddress(r)}</p>
                            </div>
                        </div>
                    ))}
                </div>
            }
            {
                error &&
                <div className="message-wrapper">
                    <div className="message-title">{error.title}</div>
                    <div className="message-description">{error.description}</div>
                </div>
            }
            {showAddHome &&
                <div className='add-home-footer'>
                    <div className='btn-wrapper' onClick={save}>
                        <SvgIcon name="ic_check" size={16} color="#2B8FFF" />
                        <div className='btn-wrapper-title'>Save</div>
                    </div>
                    <div className='btn-wrapper' onClick={clearInput}>
                        <SvgIcon name="ic_close" size={16} color="#DC1D1D" />
                        <div className='btn-wrapper-title'>Cancel</div>
                    </div>
                </div>
            }
        </div >
    );
}
