import { css } from "@emotion/css";
import polyline from "@mapbox/polyline";
import { LngLatBounds, Popup } from '@ndrive/nmaps-gl';
import { flatten } from 'lodash';
import React, { useEffect, useRef } from 'react';
import { markerUrl, routingLayerName, routingSourceName, waypointMarkerUrl, waypointsLayerName, waypointsSourceName } from '../../constants';
import { SvgIcon } from "../../icons";

const cardStyle = css({
    position: "absolute",
    top: 12,
    left: 12,
    minWidth: 150,
    maxWidth: 300,
    borderRadius: 8,
    background: "#FFF",
    padding: "8px 12px",
    boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.15)",

    "&>div": {
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
        fontSize: 12,

        "&:not(:last-child)": {
            marginBottom: 2
        }
    },

    "& .waypoint-wrapper": {
        display: "flex",
        alignItems: "center",
        color: "#393939",
        fontSize: 14,

        "svg": {
            marginRight: 4
        },

        "&:not(:last-child)": {
            marginBottom: 7
        }

    }
});

const getCustomColor = (styleName) => {
    let color;
    switch (true) {
        case /dark/i.test(styleName):
            color = "#8C8C8D";
            break;
        case /light/i.test(styleName):
            color = "#C6C6C6";
            break;
        case /satellite/i.test(styleName):
            color = "#F4F4F4";
            break;
        default:
            color = "#A8A8A8";
            break;
    }
    return color;
}

const getTrafficColor = (severity, selectedAlternative, alternative) => {
    let color;
    const opacity = selectedAlternative === alternative ? 1 : 0.4;
    switch (severity) {
        case 0:
            color = `rgb(18, 98, 144, ${opacity})`;
            break;
        case 1:
            color = `rgb(78, 199, 91, ${opacity})`;
            break;
        case 2:
            color = `rgb(237, 114, 6, ${opacity})`;
            break;
        case 3:
            color = `rgb(220, 29, 29, ${opacity})`;
            break;
        case 4:
            color = `rgb(142, 23, 23, ${opacity})`;
            break;
        default:
            break;
    }
    return color;
}

const formatDistance = (distance, units, full) => {
    if (units === "Imperial") {
        const miles = distance * 0.000621371192;
        return miles < 1 ? `${miles.toFixed(2)} mile` : `${miles.toFixed(1)} miles`;
    } else {
        return distance > 1000 ? `${Math.round(distance / 1000)} ${full ? "kilometers" : "km"}` : `${Math.round(distance)} ${full ? "meters" : "m"}`;
    }
}

const formatTime = (time) => {
    const treatedTime = Math.round(time / 60);
    if (time < 60) {
        return `${Math.round(time)} sec`;
    } else {
        return treatedTime > 60 ? `${Math.trunc(treatedTime / 60)} h ${treatedTime % 60} min` : `${treatedTime} min`;
    }
}

const getProfile = (profileCriteria) => {
    const profiles = {
        "Car": {
            "icon": "ic_car",
            "mode": "fastest",
            "transportation": "Car",
        },
        "Truck": {
            "icon": "ic_truck",
            "mode": "fastest",
            "transportation": "Truck"
        },
        "Walking": {
            "icon": "ic_walk",
            "mode": "pedestrian",
            "transportation": "Car"
        }
    };

    const profilesKeys = Object.keys(profiles);
    const matchingKey = profilesKeys.find((key) => {
        const profile = profiles[key];
        return profileCriteria.mode === profile.mode && profileCriteria.transportation === profile.transportation;
    });
    return profiles[matchingKey] || profiles[profilesKeys[0]];
}

export const EmbedRouting = ({ map, routingData }) => {

    const geoJsonRef = useRef(null);
    const hoverPopupRef = useRef(null);
    const activePopupRef = useRef(null);
    const selectedAlternativeRef = useRef(null);

    useEffect(() => {
        map.isStyleLoaded() ? addDirections(routingData) : map.once("load", () => addDirections(routingData));
    }, [routingData])

    const addDirections = (data) => {
        // Get profile
        const profile = getProfile(data.profile);

        if (map.getSource(waypointsSourceName)) {
            if (map.getLayer(waypointsLayerName)) map.removeLayer(waypointsLayerName);
            if (map.getLayer(routingLayerName)) map.removeLayer(routingLayerName);
            map.removeSource(waypointsSourceName);
        }

        // Load markers
        map.loadImage(waypointMarkerUrl).then(res => map.addImage("waypoint-marker", res.data));
        map.loadImage(markerUrl).then(res => map.addImage("custom-waypoint-marker", res.data));

        // Add source
        map.addSource(waypointsSourceName, {
            "type": "geojson",
            "data": {
                "type": "FeatureCollection",
                "features": data.waypoints?.map((wp, i) => {
                    return {
                        "type": "Feature",
                        "geometry": {
                            "type": "Point",
                            "coordinates": [wp.lng, wp.lat]
                        },
                        "properties": {
                            "id": i,
                            "name": wp.name
                        }
                    };
                })
            }
        });

        // Add layers
        map.addLayer({
            "id": waypointsLayerName,
            "type": "symbol",
            "source": waypointsSourceName,
            "layout": {
                "icon-image": "waypoint-marker",
                "text-size": 14,
                "icon-size": 1,
                "text-field": ["get", "name"],
                "text-anchor": "left",
                "icon-anchor": "bottom",
                "text-offset": [1.2, -1.2],
            },
            "paint": {
                "text-color": "#000000",
                "text-halo-color": "#FFFFFF",
                "text-halo-width": 1.8
            }
        });

        map.addLayer({
            "id": routingLayerName,
            "type": "symbol",
            "source": waypointsSourceName,
            "layout": {
                "icon-image": "custom-waypoint-marker",
                "icon-size": 1,
                "icon-anchor": "bottom",
                "text-size": 14,
                "text-field": ["get", "name"],
                "text-anchor": "left",
                "text-offset": [1.2, -1.2],
            },
            "paint": {
                "text-color": "#000000",
                "text-halo-color": "#FFFFFF",
                "text-halo-width": 1.8
            },
            "filter": ["==", "id", data.waypoints?.length - 1]
        });

        // Persist layers
        map.persistLayers([waypointsLayerName]);
        map.persistLayers([routingLayerName]);

        // Fit bounds
        const bounds = data.waypoints?.map(wp => { return { "lng": wp.lng, "lat": wp.lat } }).reduce((bounds, coord) => {
            return bounds.extend(coord);
        }, new LngLatBounds({ "lng": data.waypoints[0].lng, "lat": data.waypoints[0].lat }));
        map.fitBounds(bounds, { padding: 100, animate: false });

        // Add and set data theme layer/style
        const geojsonDefinition = {
            type: "geojson",
            data: {
                type: "FeatureCollection",
                features: []
            }
        };

        if (!map.getSource(routingSourceName)) {
            map.addSource(routingSourceName, geojsonDefinition);
        }

        // Create vars
        let _geojson = {
            type: "FeatureCollection",
            features: []
        };
        let layers = [];
        selectedAlternativeRef.current = "result";

        const geometries = flatten([
            flatten(data.result.legs.map((leg) => {
                return { "name": "result", "geometry": leg.geometry, "eta": data.result.total_time, "distance": data.result.total_distance };
            })),
            flatten((data.alternatives || []).map((c, i) => {
                return c.legs.map((legAlt) => {
                    return { "name": `alternative-${i}`, "geometry": legAlt.geometry, "eta": data.alternatives[i].total_time, "distance": data.alternatives[i].total_distance };
                });
            }))
        ]);

        geometries.forEach((g) => {
            const features = [];
            const decoded = polyline.decode(g["geometry"], 6).map((c) => {
                return c.reverse();
            });
            decoded.forEach((c) => {
                const previous = features[features.length - 1];

                if (previous) {
                    previous.geometry.coordinates.push(c);
                } else {
                    const segment = {
                        "type": "Feature",
                        "properties": {
                            "name": g["name"]
                        },
                        "geometry": {
                            "type": "LineString",
                            "coordinates": []
                        }
                    };
                    // New segment starts with previous segment's last coordinate.
                    if (previous) segment.geometry.coordinates.push(previous.geometry.coordinates[previous.geometry.coordinates.length - 1]);
                    segment.geometry.coordinates.push(c);
                    features.push(segment);
                }
            });
            _geojson.features = _geojson.features.concat(features);

            // Define new layer
            const style = map.getStyle();
            const lineColor = getCustomColor(map.getStyle().name);
            const layer = {
                "id": `routing-${g["name"]}`,
                "type": "line",
                "source": routingSourceName,
                "paint": {
                    "line-color": g["name"] === "result" ? "#4ca0fe" : lineColor,
                    "line-width": 4
                },
                "layout": {
                    "visibility": "visible"
                },
                "filter": ["==", "name", g["name"]]
            };
            layers.push(layer);

            // Check if map has this layer
            if (!map.getLayer(`routing-${g["name"]}`)) {
                map.addLayer(layer);
                if (g["name"] !== "result" && map.getLayer("routing-result")) {
                    map.moveLayer(`routing-${g["name"]}`, "routing-result");
                }
                map.on("click", `routing-${g["name"]}`, () => {
                    if (hoverPopupRef.current) {
                        hoverPopupRef.current.remove();
                    }
                    changeAlternative(profile, g["name"], _geojson);
                });
            } else {
                style.layers.forEach((layer) => {
                    if (routingSourceName === layer["source"]) {
                        if (layer.id !== "routing-result") {
                            map.setPaintProperty(layer.id, "line-color", "#A8A8A8");
                            map.moveLayer(layer.id, "routing-result");
                        }
                    }
                });
                map.setPaintProperty("routing-result", "line-color", "#4ca0fe");
            }

            // Change the cursor to a pointer when the mouse is over.
            map.on("mouseenter", `routing-${g["name"]}`, (e) => {
                if (g["name"] !== selectedAlternativeRef.current) {
                    let result;
                    if (g["name"] === "result") result = routingData["result"];
                    else {
                        const idx = g["name"].split("-")[1];
                        result = routingData["alternatives"][idx];
                    }
                    if (hoverPopupRef.current) {
                        hoverPopupRef.current.remove();
                    }
                    const popupContent = createHTMLPopup(result, profile, false);
                    hoverPopupRef.current = new Popup({ anchor: "left", closeButton: false, closeOnClick: false, className: "alternative-popup" })
                        .setLngLat(e.lngLat)
                        .setHTML(popupContent)
                        .addTo(map);
                    map.getCanvas().style.cursor = "pointer";
                }
            });
            // Change it back to a pointer when it leaves.
            map.on("mouseleave", `routing-${g["name"]}`, () => {
                map.getCanvas().style.cursor = "";
                if (hoverPopupRef.current) {
                    hoverPopupRef.current.remove();
                }
            });
        });

        // Check traffic segments
        const trafficGeometries = flatten([
            flatten(data.result.legs).map((leg) => {
                return flatten((leg["traffic"]?.severity_segments || []).map((traffic_seg) => {
                    return traffic_seg.geometry.map((traffic_geo, i) => {
                        return { "name": `result-traffic-${traffic_seg.severity}-${i}`, "alternative": "result", "geometry": traffic_geo, "severity": traffic_seg.severity };
                    });
                }));
            }),
            flatten((data.alternatives || []).map((c, i) => {
                return c.legs.map((legAlt) => {
                    return flatten((legAlt["traffic"]?.severity_segments || []).map((traffic_seg) => {
                        return traffic_seg.geometry.map((traffic_geo, j) => {
                            return { "name": `alternative-${i}-traffic-${traffic_seg.severity}-${j}`, "alternative": `alternative-${i}`, "geometry": traffic_geo, "severity": traffic_seg.severity };
                        });
                    }));
                });
            }))
        ]).flat(1);

        trafficGeometries.forEach((g) => {
            const features = [];
            const decoded = polyline.decode(g["geometry"], 6).map((c) => {
                return c.reverse();
            });
            decoded.forEach((c) => {
                const previous = features[features.length - 1];

                if (previous) {
                    previous.geometry.coordinates.push(c);
                } else {
                    const segment = {
                        "type": "Feature",
                        "properties": {
                            "name": g["name"],
                            "severity": g["severity"],
                            "alternative": g["alternative"]
                        },
                        "geometry": {
                            "type": "LineString",
                            "coordinates": []
                        }
                    };
                    // New segment starts with previous segment's last coordinate.
                    if (previous) segment.geometry.coordinates.push(previous.geometry.coordinates[previous.geometry.coordinates.length - 1]);
                    segment.geometry.coordinates.push(c);
                    features.push(segment);
                }
            });
            _geojson.features = _geojson.features.concat(features);
            const layer = {
                "id": `${g["name"]}`,
                "type": "line",
                "source": routingSourceName,
                "paint": {
                    "line-color": getTrafficColor(g["severity"], selectedAlternativeRef.current, g["alternative"]),
                    "line-width": 4
                },
                "layout": {
                    "visibility": "visible"
                },
                "filter": ["==", "name", g["name"]]
            };
            layers.push(layer);

            // Check if map has this layer
            if (!map.getLayer(`${g["name"]}`)) map.addLayer(layer);
        });

        // Store GEO JSON
        geoJsonRef.current = _geojson;

        createAlternativePopup(profile, "result");

        // Persist layers
        map.persistLayers(layers.map(l => l.id));

        // Add source and move layers
        map.getSource(routingSourceName).setData(_geojson);
        map.moveLayer(waypointsLayerName);
        map.moveLayer(routingLayerName);
    }

    const changeAlternative = (profile, id) => {
        selectedAlternativeRef.current = id;

        // Update popup
        createAlternativePopup(profile, id);

        // Update Layers
        const style = map.getStyle();
        style.layers.forEach((layer) => {
            if (routingSourceName === layer["source"]) {
                if (layer.id.startsWith("routing")) {
                    map.setPaintProperty(layer.id, "line-color", "#A8A8A8");
                    if (layer.id !== `routing-${id}`) map.moveLayer(layer.id, `routing-${id}`);
                }
                if (layer.id.includes("traffic")) {
                    const feature = geoJsonRef.current.features.find(f => f.properties.name === layer.id);
                    if (feature) {
                        const prop = feature.properties;
                        map.setPaintProperty(layer.id, "line-color", getTrafficColor(prop.severity, selectedAlternativeRef.current, prop.alternative));
                    }
                }
            }
        });
        map.setPaintProperty(`routing-${id}`, "line-color", "#4ca0fe");
        map.moveLayer(waypointsLayerName);
        map.moveLayer(routingLayerName);
    };

    const createAlternativePopup = (profile, alternative) => {
        const feature = geoJsonRef.current.features.find(feature => feature.properties.name === alternative);

        // Active popup
        const coordinates = feature.geometry.coordinates;
        const lngLat = coordinates[Math.floor(coordinates.length / 2)];

        // Get result
        let result;
        if (alternative === "result") result = routingData["result"];
        else {
            const idx = alternative.split("-")[1];
            result = routingData["alternatives"][idx];
        }

        const popupContent = createHTMLPopup(result, profile, true);
        if (activePopupRef.current) {
            activePopupRef.current.setLngLat(lngLat).setHTML(popupContent);
        } else {
            activePopupRef.current = new Popup({ anchor: "left", closeButton: false, closeOnClick: false, className: "alternative-popup" })
                .setLngLat(lngLat)
                .setHTML(popupContent)
                .addTo(map);
        }
    }

    const createHTMLPopup = (result, profile, active) => {
        return `
        <div class="alternative-icon ${profile.icon} ${active ? "active" : ""}"></div>
        <div>
            <div class="popup-label popup-time ${active ? "active" : ""}">${formatTime(result.total_time)}</div>
            <div class="popup-label popup-distance">${formatDistance(result.total_distance, "Metric", false)}</div>
        </div>
    `;
    };

    return (
        <div className={cardStyle}>
            {routingData.waypoints.map((wp, i) => (
                <div className="waypoint-wrapper" key={`waypoint-${i}`}>
                    <SvgIcon className="icon" name={`${i === routingData.waypoints.length - 1 ? "ic_destination" : "ic_origin"}`} size={16} color="#8C8C8D" />
                    <div>{wp.name}</div>
                </div>
            ))}
        </div>
    )
}
